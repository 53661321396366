import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import $ from 'jquery';

import CollectionListItem from './CollectionListItem';

import './CollectionList.css';


const CollectionList = (props) => {

	const {
		loading,
		selectable,
		collections,
	} = props;


	function triggerSelectionHeader(e) {
		$(document.body).addClass('-showSelectionHeader');
	}

	if (loading) {
		return (
			<div className="collectionList">
				<CollectionListItem
					loading
				/>
				<CollectionListItem
					loading
				/>
			</div>
		);
	}


	return (
		<div className="collectionList">
			{collections.map((collection, i) => (
				<div key={`${collection.slug}-${i}`}>
					{ selectable &&
						<div className="listItemSelector">
							<div className="listItemSelectorBackground"></div>
							<div className="listItemSelectorContent">
								<Checkbox onClick={triggerSelectionHeader} />
							</div>
						</div>
					}
					<CollectionListItem {...collection} />
				</div>
			))}

			{(!collections || !collections.length) &&
				<p>There are no collections yet.</p>
			}
		</div>
	);
};

CollectionList.propTypes = {
	collections: PropTypes.array,
	loading: PropTypes.bool,
	selectable: PropTypes.bool,
};

CollectionList.defaultProps = {
	collections: [],
};

export default CollectionList;
