import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import autoBind from 'react-autobind';
import _s from 'underscore.string';
import stripTags from 'underscore.string/stripTags';

import Card from '../../cards/Card';
import ListLoadingItem from '../ListLoadingItem';
import ListLoadingComment from '../ListLoadingComment';
import { toggleItemSelected } from '../../../../modules/items/actions';
import formatItemDataForCard from '../../../../lib/formatItemDataForCard';

import './List.css';

const convertDescriptionToPlainText = jsonString => {
	if (!jsonString) return;
	let str = "";
	try {
		const json = JSON.parse(jsonString);
		str = json.blocks.map(b => b.text).join(' ');
	} catch (e) {
		// console.error(`Failed to parse item description: ${e}`);
		str = jsonString;
	}

	return stripTags(str);
};

const renderItem = (item, isPosts, isAdmin) => {
	let card;
	const {
		itemUrl,
		metadata,
		imageUrl,
		videoSrc,
		audioSrc,
		pdfSrc,
		mediaUrl,
	} = formatItemDataForCard(item);

	let cardItemUrl = (isAdmin ? `${itemUrl}/edit` : itemUrl);
	if (imageUrl) {
		card = (
			<Card
				to={cardItemUrl}
				key={_s.slugify(item.title)}
				className="listItemCard"
				title={item.title}
				textShort={_s.prune(convertDescriptionToPlainText(item.description), 150)}
				assetImageSrc={imageUrl}
				assetImageWidth={1000}
				assetImageAlt={item.title} // is that correct?
				metadata={metadata}
			/>
		);
	} else if (videoSrc) {
		card = (
			<Card
				to={cardItemUrl}
				key={_s.slugify(item.title)}
				className="listItemCard"
				title={item.title}
				textShort={_s.prune(convertDescriptionToPlainText(item.description), 150)}
				assetVideoSrc={videoSrc}
				metadata={metadata}
			/>
		);
	} else if (audioSrc) {
		card = (
			<Card
				to={cardItemUrl}
				key={_s.slugify(item.title)}
				className="listItemCard"
				title={item.title}
				textShort={_s.prune(convertDescriptionToPlainText(item.description), 150)}
				assetAudioSrc={audioSrc}
				metadata={metadata}
			/>
		);
	} else if (pdfSrc) {
		card = (
			<Card
				to={cardItemUrl}
				key={_s.slugify(item.title)}
				className="listItemCard"
				title={item.title}
				textShort={_s.prune(convertDescriptionToPlainText(item.description), 150)}
				assetPdfSrc={pdfSrc}
				metadata={metadata}
			/>
		);
	} else if (mediaUrl) {
		card = (
			<Card
				to={cardItemUrl}
				key={_s.slugify(item.title)}
				className="listItemCard"
				title={item.title}
				textShort={_s.prune(convertDescriptionToPlainText(item.description), 150)}
				assetMediaSrc={mediaUrl}
				metadata={metadata}
			/>
		);
	} else if (item.commenter) {
		card = (
			<Card
				to={cardItemUrl}
				key={_s.slugify(item.title)}
				className="listItemCard"
				title={item.title}
				textLong={_s.prune(item.body, 200)}
				metadata={metadata}
				footer={{
					authorURL: `/@${item.commenter.slug}`,
					authorName: item.commenter.name,
					authorImageURL: item.commenter.image,
					postedDate: item.updated,
				}}
			/>
		);

	} else if (item.user) {
		// TODO: user

	} else {
		card = (
			<Card
				to={cardItemUrl}
				key={_s.slugify(item.title)}
				className="listItemCard"
				title={item.title}
				textLong={_s.prune(convertDescriptionToPlainText(item.description), 240)}
				metadata={metadata}
			/>
		);
	}
	return card;
};

class List extends React.Component {

	constructor(props) {
		super(props);
		autoBind(this);
	}


	render() {

		const {
			loading,
			loadingComments,
			hasMoreToLoad,
			selectable,
			selectedItemIds,
			items,
			isPosts,
			isAdmin,
		} = this.props;

		if (loading) {
			return (
				<ListLoadingItem />
			);
		} else if (loadingComments) {
			return (
				<ListLoadingComment />
			);
		}

		const classes = ['list'];

		if (hasMoreToLoad) {
			classes.push('hasMoreToLoad');
		}

		return (
			<div className={classes.join(' ')}>
				{items.map((item, i) => item && (
					<div
						className=
							{
								`listItem
								${selectable ? '-selectable' : ''} ${items.length === 1 ? '-listItemLengthOne' : ''}
								${(selectedItemIds.indexOf(item._id) >= 0) ? '-selected' : ''}
								`
							}
						key={`${item.slug}-${i}`}
					>
						{ selectable &&
							<div className='listItemSelector'>
								<Checkbox
									onClick={this.props.dispatchToggleItemSelect.bind(this, item._id)}
									checked={(selectedItemIds.indexOf(item._id) >= 0)}
								/>
							</div>
						}
						{renderItem(item, isPosts, isAdmin)}
					</div>
				))}
			</div>
		);
	}
}

List.propTypes = {
	items: PropTypes.array,
	loading: PropTypes.bool,
	loadingComments: PropTypes.bool,
	hasMoreToLoad: PropTypes.bool,
	selectable: PropTypes.bool,
	isPosts: PropTypes.bool,
	isAdmin: PropTypes.bool,
};

List.defaultProps = {
	items: [],
	selectedItemIds: [],
};


export default List;
