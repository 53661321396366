import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { from } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import Cookies from 'universal-cookie';


const cookies = new Cookies();

const httpLink = new HttpLink({
	uri: `${process.env.REACT_APP_GRAPHQL_SERVER}/${process.env.REACT_APP_GRAPHQL_URI}`,
	credentials: 'include',
});


const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.map(({ message, locations, path }) =>
			console.log(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
			),
		);

	if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const token = cookies.get('token') || null;

	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI1YUhWSE82MSIsInVzZXJOYW1lIjoidGVzdEBhcmNoaW1lZGVzLmRpZ2l0YWwiLCJleHAiOjE2MDIzMDk5NTYsImlhdCI6MTU3MDc3Mzk1Nn0.UJ0dZt1ITCSqwM3zIjzH_LzJVcxhZsI5G6KM7Q7rxv0",
		}
	}
});

const cache = new InMemoryCache();

const client = new ApolloClient({
	link: from([errorLink, authLink, httpLink]),
	cache,
});

export default client;
