import React from 'react';
import autoBind from 'react-autobind';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import MediaViewer from  '../../../../components/common/MediaViewer'

import './ItemMetaFieldMedia.css';


class ItemMetaFieldMedia extends React.Component {
	constructor(props) {
		super(props);

		autoBind(this);
	}


	setActiveFile(file) {
		// window.open(`https://s3.amazonaws.com/iiif-orpheus/${file.name}`);
		window.open(file.name);
	}

	render() {
		const { label, value, } = this.props;
		let files = [];

		if (value) {
			files = JSON.parse(value);

		}

		return (
			<div className="itemMetaField">
				<div className="itemMetaFieldLabel">
					<Typography variant="caption">{label}</Typography>
				</div>
				<div className="itemMetaFieldValue -media">
					<MediaViewer files={files} thumbnailSize={250}/>
				</div>
			</div>
		);
	}
}

export default ItemMetaFieldMedia;
