
import React from 'react';
import _ from 'underscore';
import _s from 'underscore.string';
import qs from 'qs-lite';
import slugify from 'underscore.string/slugify';
import stripTags from 'underscore.string/stripTags';
import prune from 'underscore.string/prune';
import moment from 'moment';

import Root from '../../../../containers/Root';
import List from '../../../../components/common/lists/List';
import IssueListContainer from '../../containers/IssueListContainer';

import issuesData from '../../../../lib/issues';


let issues = issuesData.data.issues;
let issuesCount = 0;
const _issues = [];

const params = qs.parse(window.location.search.replace('?', ''));

// date search
if (params['Date']) {
	const query = qs.parse(window.location.search.replace('?', ''));
	let pathname = window.location.pathname;
	let selectedFilterValues;
	const filterTitle = "Date";

	if (query[filterTitle]) {
		selectedFilterValues = query[filterTitle].split('+');
	}

	const maxDate = new Date(selectedFilterValues[0]).getFullYear();
	const minDate = new Date(selectedFilterValues[selectedFilterValues.length - 1]).getFullYear();

	issues = issues.filter((issue) => {
		let match = false;
		if (issue.customFields) {
			issue.customFields.forEach((cf) => {
				if (cf.name === 'Date') {
					let date = new Date(cf.value);
					let year = date.getFullYear();
					if (minDate <= year && year <= maxDate) {
						match = true;
					}
				}
			})
		}

		return match;
	})
	issuesCount = issues.length;
}

issues.forEach(issue => {
	const newIssue = {
		_id: issue.id,
		name: issue.name,
		slug: slugify(issue.title),
		title: issue.title,
		description: '',
		files: [],
		metadata: [],
	};

	const descriptionFields = [];
	issue.articles.forEach(article => {
		const authorFields = [];
		article.authors.forEach(author => {
			authorFields.push(author.name);
		});
		descriptionFields.push(`${article.title} by ${authorFields.join(', ')}`);
	});
	newIssue.description = prune(stripTags(descriptionFields.join('; ')), 220);

	if (issue.customFields) {
		issue.customFields.forEach(customField => {
			if (['Volume', 'Author'].indexOf(customField.name) >= 0) {
				if (customField.value && customField.value.length) {
					newIssue.metadata.push({
						type: 'text',
						label: customField.name,
						value: customField.value,
					});
				}
			} else if (customField.name === 'Date') {
				newIssue.metadata.push({
					type: 'text',
					label: customField.name,
					value: moment(customField.value).format("MMMM YYYY"),
				});
			} else if (customField.name === 'Thumbnail') {
				issue.thumbnail = customField.value;
				newIssue.thumbnail = customField.value;
			}
		});
	}

	issue.articles.forEach(article => {
		article.files.forEach(file => {
			if (file.fileURI.endsWith('.jpg') || file.fileURI.endsWith('.jpeg')) {
				newIssue.files.push({
					type: 'image/jpeg',
					name: file.fileURI,
          path: file.fileURI,
				});
			}
		});
	});


	let includeInResults = true;

	// check text search
	if (params.s && params.s.length) {
		const textsearch = params.s;
		includeInResults = false;

		if (issue.title.includes(textsearch)) {
			includeInResults = true;
		}
		if (newIssue.description && newIssue.description.includes(textsearch)) {
			includeInResults = true;
		}

		issue.articles.forEach(article => {
			if (article.title.includes(textsearch)) {
				includeInResults = true;
			}
			if (article.content && article.content.includes(textsearch)) {
				includeInResults = true;
			}
			if (article.ecompanionhtml && article.ecompanionhtml.includes(textsearch)) {
				includeInResults = true;
			}
		});
	}


	// check author search
	if (params.Authors && params.Authors.length) {
		const authorSearch = params.Authors.split('+');
		includeInResults = false;

		console.log(authorSearch);
		issue.articles.forEach(article => {
			const authorFields = [];
			article.authors.forEach(author => {
				authorFields.push(author.name);
			});
			console.log(authorFields);
			if(authorFields.filter(value => authorSearch.includes(value)).length) {
				includeInResults = true;
			}
		});
		console.log(includeInResults);
	}



	if (includeInResults) {
		_issues.push(newIssue);
	}
});

const Issues = () => {
  return (
  	<List
  		items={_issues}
  	/>
  );
}

export default Issues;
