import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import editorReducers from '../modules/orpheus-editor/reducers';
import client from '../middleware/apolloClient';


import * as ActionTypes from '../actions';


const errorMessage = (state = null, action) => {
	const {type, error} = action;

	if (type === ActionTypes.RESET_ERROR_MESSAGE) {
		return null;
	} else if (error) {
		return error;
	}

	return state;
};

const rootReducer = combineReducers({
	form: formReducer,
	errorMessage,
	editor: editorReducers,
});

export default rootReducer;
