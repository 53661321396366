import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const issueListQuery = graphql(gql`
	query issueListQuery {
		issues {
			id
			title
			name
      articles {
        id
        title
        files {
          id
          fileURI
        }
      }
      customFields {
        id
        issue_id
        name
        value
      }
		}
	}
`, {
	name: 'issueListQuery',
	props: props => ({
		issues: props.issueListQuery.issues,
		loading: props.issueListQuery.loading,
	}),
});

export default issueListQuery;
