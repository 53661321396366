import React from 'react';
import moment from 'moment-timezone';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';

const ItemMetaFieldDate = ({ value, label }) => {
	let dateStr = '';
	let date = null;

	if (value) {
		date = new moment(value);
		dateStr = date.format('LL');
	}

	return (
		<div className="itemMetaField itemMetaFieldDate">
			<div className="itemMetaFieldLabel">
				<Typography variant="caption">
					{label}
				</Typography>
			</div>
			<div className="itemMetaFieldValue">
				<Typography variant="body2">
					{dateStr}
				</Typography>
			</div>
		</div>
	);
};

export default ItemMetaFieldDate;
