import React from 'react';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player'
import OpenSeadragon from 'openseadragon';
import autoBind from 'react-autobind';
import Typography from "@material-ui/core/Typography";

import IconButton from '@material-ui/core/IconButton';
import IconClose from '@material-ui/icons/Close';
import IconFullscreenExit from '@material-ui/icons/FullscreenExit';
import IconEdit from '@material-ui/icons/Edit';

import Dialog from '@material-ui/core/Dialog';
import $ from 'jquery';

// import './MediaViewer.css';

import AnnotationList from '../AnnotationList';
import AnnotationDetail from '../AnnotationDetail';

class FileViewer extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			osdLoading: false,
			osd: false,
			tile: false,
			drawMode: false,
			canAddAnnotations: false,
			activeAnnotationI: 0,
			annotationDetailOpen: false,
			modalOpened: false,
			thumbnailsOpen: true,
			initialZoom: false,
			loadingImage: false,
      isZooming: false,
      zoomCheckTimeout: false
		};
		this.viewerRef = React.createRef();
		autoBind(this);
	}


	componentDidMount() {
		if (this.props.file) {
			this.initOpenseadragon();
		}

	}

	componentDidUpdate(prevProps) {
		let file = this.props.file;
		if (!file || !file.path && file.name && !file.name.includes('oral-tradition')) {return;}

		const fileType = file.type;
		const isImage = fileType.slice(0, fileType.indexOf('/')) === 'image';
		if (!isImage) {return;}

		if (!this.state.osd) {
			this.initOpenseadragon();
		} else if (prevProps.file && this.props.file.name !== prevProps.file.name){
			this.setState({osdLoading: true, initialZoom: false}, () => {
        if (window.location.hostname.includes('oraltradition')) {
          osd.open({
						type: 'image',
						url: file.name,
					})
        } else {
          osd.open(`//iiif.orphe.us/${this.props.file.name}/info.json`)
        }
			})
		}

		if (this.props.thumbnailsOpen && this.props.thumbnailsOpen !== prevProps.thumbnailsOpen) {
			// fix for when thumbnails opened and closed, image would get smaller
			this.resetZoom();
		}
	}

	resetZoom() {
		if (this.state.osd) {
			osd.viewport.zoomTo(this.state.initialZoom);
		}
	}

	initOpenseadragon() {
		let file = this.props.file;
		if (!file || !file.path && !file.name) {return;}

		const fileType = file.type;
		const isImage = fileType.slice(0, fileType.indexOf('/')) === 'image';
		if (isImage) {
			this.showLoading();

			// await setTimeout(() => {}, 0);
			const elem = document.getElementById('viewer');
			if (elem) {


        let 	tileSources = `//iiif.orphe.us/${file.name}/info.json`;

        if (window.location.hostname.includes("oraltradition")) {
          tileSources = {
						type: 'image',
						url: file.name,
					}
        }
				const viewer = OpenSeadragon({
					id: 'viewer',
					tileSources: tileSources,
					showZoomControl: false,
					showHomeControl: false,
					showFullPageControl: false,
					showRotationControl: false,
					viewportMargins: {
						bottom: 60,
						top: 60,
						left: 60,
						right: 60,
					},
					visibilityRatio: 0.8,
					gestureSettingsMouse: {
						clickToZoom: false,
						dblClickToZoom: true
					},
					defaultZoomLevel: 0,
					visibilityRatio: 0.8,
				});

				viewer.addHandler('open', () => {
					const tiledImage = viewer.world.getItemAt(0);
					this.setState({tile: tiledImage, loadingImage: true})

					if (tiledImage.getFullyLoaded()) {
						this.hideLoading();
						this.setState({loadingImage:false})
					} else {
						this.setState({loadingImage:false})

						tiledImage.addOnceHandler('fully-loaded-change', this.hideLoading);
					}
				});


				viewer.addHandler('zoom', (event) => {
          this.handleZoomEvent(event);

				})

				this.setState({osd: viewer})
				window.osd = viewer
			}
		} else {
			this.setState({initialZoom: false})
		}
	}


	handleZoomEvent(event) {
	  if (!this.state.initialZoom) {
	    this.setState({initialZoom: event.zoom});
	  }
	  if (!this.state.isZooming) {
	    this.setState({isZooming: true})
	  };


	  let initialZoom = this.state.initialZoom || event.zoom;
	  if (event.zoom > initialZoom) {
	    if (document.getElementById('viewer')) {
	      document.getElementById('viewer').querySelector('.openseadragon-canvas').focus();
	    }
	  }

	  this.props.handleZoom((event.zoom <= initialZoom));
	    if (this.state.zoomCheckTimeout) {
	      clearTimeout(this.state.zoomCheckTimeout)
	    }

	    let timeout = window.setTimeout(()=>{
	      this.setState({isZooming: false}, () =>  {
	      })
	    }, 550)
	    this.setState({zoomCheckTimeout: timeout})

	}


	afterNewAnnotation() {
		this.setState({annotationDetailOpen: false, drawMode: false, canAddAnnotations: false})
	}

	showLoading() {
		this.setState({
			osdLoading: true,
		});
	}

	hideLoading() {
		this.setState({
			osdLoading: false,
		});
	}

	toggleAnnotationDetail() {
		this.setState({
			annotationDetailOpen: !this.state.annotationDetailOpen
		});
	}

	hideAnnotationsDetails() {
		this.setState({
			annotationDetailOpen: false
		})
	}

	showAnnotationsDetails() {
		this.setState({
			annotationDetailOpen: true
		})
	}

	setActiveAnnotationI(index) {
		// this.props.toggleDrawMode();
		this.props.turnOffDrawMode();

		this.setState({
			activeAnnotationI: index,
			annotationDetailOpen: false,
		}, () => {
			// hack to update detail when already opened
			this.setState({
				annotationDetailOpen: true
			})
		})

	}


	renderMedia () {
		let file = this.props.file;
		let viewer;
		let type;
		let url;

		if (!file) {
			return null;
		}

		if (file.path && file.type) {

			/*
			if (file.type.indexOf("image") >= 0) {
				let path = `//iiif.orphe.us/${file.name}/full/${thumbnailSize},/0/default.jpg`;
				type = 'image';

				viewer = (
					<img src={path} alt={file.title} onError={(event)=>{
						event.target.src=`//iiif.orphe.us/${file.name}/full/full/0/default.jpg`;
					}}/>
				)
			} else */
			if (file.type.indexOf("video") >= 0) {
				type = 'video';
				// url = `https://s3.amazonaws.com/iiif-orpheus/${file.name}`;
				url = file.name;
				viewer =(
					<ReactPlayer
						url={file.name} // file.path ? `https://s3.amazonaws.com/iiif-orpheus/${file.name}` : file.name}
						width="60vw"
						height="60vh"
						controls
						style={{
							display: 'flex',
							alignItems: 'center',
							margin: '0 auto',
						}}
					/>
				);
			} else if (file.type.indexOf("audio") >= 0) {
				type = 'audio';
				// url = `https://s3.amazonaws.com/iiif-orpheus/${file.name}`;
				url = file.name;
				viewer =(
					<ReactPlayer
						url={url}
						width="60vw"
						height="60px"
						controls
						style={{
							margin: '0 auto',
						}}
					/>
				);
			} else if (file.type.indexOf("pdf") >= 0) {
				type = 'pdf';
				viewer = (
					<embed
						src={
	            file.path ?
	              `https://drive.google.com/viewerng/viewer?embedded=true&url=${file.path}` :
	              file.name
	           }
						style={{padding: 60}}
						width={window.innerWidth }
						height={window.innerHeight}
					/>
				);
			}
		}

		return viewer;
	}



	render () {
		const file = this.props.file
		if (!file) {return ''}


		let annotation = false;
		let showDetails = false;

		if (file.annotations) {
			annotation = file.annotations[this.state.activeAnnotationI];
			showDetails = ( this.props.editMode || annotation && annotation.content ) ;
		}

		return (
			<div className={`MediaModalContent mediaViewer ${this.state.isZooming ? ' zooming' : ' not-zooming'}`}>
				{this.renderMedia()}
				<div id="viewer" ref={this.viewerRef}  className={(file && file.type.includes('image') ? 'visible' : 'invisible')} >
					{this.state.osdLoading  && <div className="viewerLoading" />}
				</div>

				{!this.state.loadingImage && this.props.showAnnotations && this.state.osd && this.state.tile && file && this.props.file.annotations &&
					<div>
						<AnnotationList
							openSeadragon={this.state.osd}
							tile={this.state.tile}
							isDrawingMode={this.props.drawMode}
							file={file}
              editMode={this.props.editMode}
							setActiveAnnotationI={this.setActiveAnnotationI}
							canAddAnnotations={this.state.canAddAnnotations}
							hideAnnotationsDetails={this.hideAnnotationsDetails}
							showAnnotationsDetails={this.showAnnotationsDetails}
							afterNewAnnotation={this.afterNewAnnotation}
							showAnnotations={this.props.showAnnotations}
							toggleShowAnnotations={this.props.toggleShowAnnotations}
              menuMouseOver={this.props.menuMouseOver}
							updateFile={this.props.updateFile} />

						{ showDetails &&

							<AnnotationDetail
								setActiveAnnotationI={this.setActiveAnnotationI}
							 annotations={this.props.file.annotations}
							 file={file}
							 activeAnnotationI={this.state.activeAnnotationI}
							 annotationDetailOpen={this.state.annotationDetailOpen}
							 hideAnnotationsDetails={this.hideAnnotationsDetails}
							 toggleAnnotations={this.toggleAnnotationDetail}
							 editMode={this.props.editMode}
							 drawMode={this.props.drawMode}
							 updateFile={this.props.updateFile}/>
						 }
						</div>
				}

			</div>
		);
	}
}

export default FileViewer;
