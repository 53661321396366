/**
 * @prettier
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import {
  AtomicBlockUtils,
  DefaultDraftBlockRenderMap,
  Editor,
  EditorState,
  KeyBindingUtil,
  Modifier,
  RichUtils,
  convertToRaw,
  getDefaultKeyBinding,
} from 'draft-js';
import isUrl from 'is-url';
import { Map } from 'immutable';

// components
import FormattingTooltip from '../popovers/formattingTooltip/FormattingTooltip';
import LinkTextInput from '../popovers/formattingTooltip/LinkTextInput';
import AddTooltip from '../popovers/addTooltip/AddTooltip';
import MediaBlock from '../blocks/MediaBlock';
import decorators from '../decorators';

// lib
import clearInlineStyles from '../../lib/clearInlineStyles';
import containsLinkEntity from '../../lib/containsLinkEntity';
import getCurrentBlock from '../../lib/getCurrentBlock';
import initializeFileReader from '../../lib/initializeFileReader';
import insertEntity from '../../lib/insertEntity';
import insertNewBlock from '../../lib/insertNewBlock';
import removeLinkEntity from '../../lib/removeLinkEntity';
import { LIST_ITEM_BLOCK_TYPES } from '../../lib/draftJsBlockTypes';
import { listTypeCandidate, makeListItem } from '../../lib/listHandlers';

import './Editor.css';

const BACKSPACE = 8;
const ONE_KEY = 49;
const TWO_KEY = 50;
const FIVE_KEY = 53;
const K_KEY = 75;
const S_KEY = 83;

// The `null` entries are both for internal
// bookkeeping and for satisfying type annotations
const BLOCK_STYLE_MAP = {
  atomic: null,
  blockquote: 'opheusBlockquote',
  'code-block': null,
  'header-five': null,
  'header-four': null,
  'header-one': null,
  'header-six': null,
  'header-three': null,
  'header-two': null,
  'ordered-list-item': null,
  paragraph: null,
  'unordered-list-item': null,
  unstyled: null,
};
export const DEFAULT_CUSTOM_KEY_COMMANDS = {
  [`alt-${ONE_KEY}`]: 'handle-cmd-alt-1', // blockType: header-one
  [`alt-${TWO_KEY}`]: 'handle-cmd-alt-2', // blockType: header-two
  [`alt-${FIVE_KEY}`]: 'handle-cmd-alt-5', // blockType: blockquote
  [K_KEY]: 'handle-cmd-k', // make a link
  [S_KEY]: 'handle-cmd-s',
};
const FILE_TYPES_WHITELIST = ['image/jpeg', 'image/png'];
const HEADER_BLOCK_TYPES = [
  'header-five',
  'header-four',
  'header-one',
  'header-six',
  'header-three',
  'header-two',
];
const blockRendererFn = block =>
  block.getType() === 'atomic'
    ? { component: MediaBlock, editable: false }
    : null;
const blockRenderMap = DefaultDraftBlockRenderMap.merge(
  Map({
    'header-one': {
      element: 'h2',
    },
    'header-two': {
      element: 'h3',
    },
    'header-three': {
      element: 'h4',
    },
    'header-four': {
      element: 'h5',
    },
    'header-five': {
      element: 'h6',
    },
  })
);
const blockStyleFn = block => BLOCK_STYLE_MAP[block.getType()] || '';

class OrpheusEditor extends React.Component {
  static propTypes = {
    customKeyCommands: PropTypes.objectOf(PropTypes.string),
    debounceTime: PropTypes.number,
    enableInlineMedia: PropTypes.bool,
    handleChange: PropTypes.func,
    handleSave: PropTypes.func,
    handleUpload: PropTypes.func,
    editorState: PropTypes.object,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    setEditorRef: PropTypes.func.isRequired,
    setEditorState: PropTypes.func.isRequired,
  };

  static defaultProps = {
    customKeyCommands: DEFAULT_CUSTOM_KEY_COMMANDS,
    debounceTime: 2000, // two seconds
    enableInlineMedia: false,
    handleChange: () => {},
    handleSave: () => {},
    handleUpload: () => {},
    editorState: EditorState.createEmpty(decorators),
    placeholder: 'Write your text...',
    readOnly: false,
    setEditorRef: () => {},
    setEditorState: () => {},
  };

  state = {
    formattingTooltipContent: null,
  };

  constructor(props) {
    super(props);

    this.editorRef = React.createRef();

    if ('undefined' === typeof FileReader) {
      console.warn(
        'Please use a browser that implements FileReader if you want to upload images.'
      );
    } else {
      this.fileReader = initializeFileReader();
    }

    autoBind(this);
  }

  handleBeforeInput(_chars, editorState) {
    const currentBlock = getCurrentBlock(editorState);
    const listType = listTypeCandidate(currentBlock);
    if (listType) {
      this.onChange(makeListItem(editorState, currentBlock, listType));

      return 'handled';
    }

    return 'not-handled';
  }

  handleKeyCommand(command, editorState) {
    if (command === 'handle-list-mode-backspace') {
      this._toggleListMode();

      return 'handled';
    }

    if (command === 'handle-blockquote-backspace') {
      this._toggleBlockquoteMode();

      return 'handled';
    }

    if (command === 'handle-header-mode-backspace') {
      this._makeCurrentBlockUnstyled();

      return 'handled';
    }

    // blockType: header-one (header)
    if (command === 'handle-cmd-alt-1') {
      this._toggleBlockType('header-one');

      return 'handled';
    }

    // blockType: header-two (subheader)
    if (command === 'handle-cmd-alt-2') {
      this._toggleBlockType('header-two');

      return 'handled';
    }

    // blockType: blockquote
    if (command === 'handle-cmd-alt-5') {
      this._toggleBlockType('blockquote');

      return 'handled';
    }

    if (
      command === 'handle-cmd-k' &&
      !editorState.getSelection().isCollapsed()
    ) {
      if (containsLinkEntity(editorState)) {
        const nextState = removeLinkEntity(editorState);

        this.onChange(nextState);
      } else {
        this._setFormattingTooltipContent(LinkTextInput);
      }

      return 'handled';
    }

    if (command === 'handle-cmd-s') {
      this.props.handleSave(convertToRaw(editorState.getCurrentContent()));

      return 'handled';
    }

    const nextState = RichUtils.handleKeyCommand(editorState, command);

    if (nextState) {
      this.onChange(nextState);

      return 'handled';
    }

    return 'not-handled';
  }

  handlePastedFiles(files) {
    if (this.fileReader) {
      // Array.prototype.forEach doesn't work with files(?)
      for (let i = 0, l = files.length; i < l; i++) {
        this._uploadFile(files[i]);
      }
    }
    return 'not-handled';
  }

  handlePastedText(text, html, editorState) {
    return 'not-handled';
  }

  handleReturn(evt, editorState) {
    if (this._isEmptyBlock()) {
      if (this._isInBlockquoteMode()) {
        evt.preventDefault();

        this._toggleBlockquoteMode();

        return 'handled';
      }

      if (this._isInListMode()) {
        evt.preventDefault();

        this._toggleListMode();

        return 'handled';
      }
    }

    if (this._isBlockWithOnlyLink()) {
      // if the current block is only a link, on enter, make either orpheus item card or embed card
      evt.preventDefault();

      if (this._isOrpheusLink()) {
        // add orpheus block
        this._makeCurrentBlockItem();
      } else {
        // add embed block
        this._makeCurrentBlockEmbed();
      }
      return 'handled';
    }

    // clear styles when starting a new paragraph
    if (!this._isInListMode()) {
      editorState = clearInlineStyles(editorState);

      this.onChange(insertNewBlock(editorState));

      return 'handled';
    }

    return 'not-handled';
  }

  keyBindingFn(e) {
    console.info(
      '%c' +
        [
          `alt?: ${e.altKey}`,
          `ctrl?: ${e.ctrlKey}`,
          `meta?: ${e.metaKey}`,
          `shift?: ${e.shiftKey}`,
          `cmdMod?: ${KeyBindingUtil.hasCommandModifier(e)}`,
          `keyCode: ${e.keyCode}`,
          `key: ${e.key}`,
        ].join('\n'),
      'color: #3b7db2'
    );

    if (e.keyCode === BACKSPACE) {
      if (this._isEmptyBlock()) {
        if (this._isInBlockquoteMode()) {
          return 'handle-blockquote-backspace';
        }

        if (this._isInListMode()) {
          return 'handle-list-mode-backspace';
        }

        if (this._isInHeaderMode()) {
          return 'handle-header-mode-backspace';
        }
      }
    }

    if (
      KeyBindingUtil.hasCommandModifier(e) &&
      this.props.customKeyCommands.hasOwnProperty(e.keyCode)
    ) {
      return this.props.customKeyCommands[e.keyCode];
    }

    // https://help.medium.com/hc/en-us/articles/214672207-Keyboard-shortcuts
    if (
      e.altKey &&
      // this is a bit of a hack to account for macOS's non-standard
      // behavior (see https://stackoverflow.com/questions/3902635/how-does-one-capture-a-macs-command-key-via-javascript)
      // one side effect is that, on Windows and Linux, alt + ctrl || alt + meta will work.
      (e.ctrlKey || e.metaKey) &&
      this.props.customKeyCommands.hasOwnProperty(`alt-${e.keyCode}`)
    ) {
      e.preventDefault();

      return this.props.customKeyCommands[`alt-${e.keyCode}`];
    }

    return getDefaultKeyBinding(e);
  }

  onChange(editorState) {
    const _editorState = this.props.editorState;

    this.props.handleChange(editorState);

    if (editorState.getCurrentContent() !== _editorState.getCurrentContent()) {
      if (this.__autoSaveDebounce) {
        clearTimeout(this.__autoSaveDebounce);
      }

      this.__autoSaveDebounce = setTimeout(
        this.props.handleSave,
        this.props.debounceTime,
        convertToRaw(editorState.getCurrentContent())
      );
    }
  }

  render() {
    const placeholder =
      this._isInListMode() && this._isEmptyBlock()
        ? ''
        : this.props.placeholder;
    const {
      editorState,
      enableInlineMedia,
      handleUpload,
      readOnly,
    } = this.props;
    const { formattingTooltipContent } = this.state;

    return (
      <div className="editor">
        <Editor
          blockRendererFn={blockRendererFn}
          blockRenderMap={blockRenderMap}
          blockStyleFn={blockStyleFn}
          editorState={editorState}
          handleBeforeInput={this.handleBeforeInput}
          handleKeyCommand={this.handleKeyCommand}
          handlePastedFiles={this.handlePastedFiles}
          handlePastedText={this.handlePastedText}
          handleReturn={this.handleReturn}
          keyBindingFn={this.keyBindingFn}
          onChange={this.onChange}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={this.editorRef}
        />
        {!readOnly && (
          <FormattingTooltip
            editorRef={this.editorRef}
            editorState={editorState}
            formattingTooltipContent={formattingTooltipContent}
            setEditorState={this.onChange}
            setFormattingTooltipContent={this._setFormattingTooltipContent}
          />
        )}
        {enableInlineMedia && (
          <AddTooltip
            editorRef={this.editorRef}
            editorState={editorState}
            handleUpload={handleUpload}
          />
        )}
      </div>
    );
  }

  _isBlockWithOnlyLink() {
    let currentBlockText = '';
    const currentBlock = getCurrentBlock(this.props.editorState);

    if (currentBlock) {
      currentBlockText = currentBlock.getText();
    }

    return isUrl(currentBlockText);
  }

  _isEmptyBlock() {
    return getCurrentBlock(this.props.editorState).getLength() === 0;
  }

  _isInBlockquoteMode() {
    return getCurrentBlock(this.props.editorState).getType() === 'blockquote';
  }

  _isInHeaderMode() {
    return HEADER_BLOCK_TYPES.includes(
      getCurrentBlock(this.props.editorState).getType()
    );
  }

  _isInListMode() {
    const { editorState } = this.props;

    const block = getCurrentBlock(editorState);

    return !!block && LIST_ITEM_BLOCK_TYPES.includes(block.getType());
  }

  _isOrpheusLink() {
    let currentBlockText = '';
    let orpheusDomainIndex = -1;
    const currentBlock = getCurrentBlock(this.props.editorState);

    if (currentBlock) {
      currentBlockText = currentBlock.getText();
    }

    orpheusDomainIndex = currentBlockText.indexOf('.orphe.us/items');

    // exception for local development
    if (orpheusDomainIndex < 0) {
      orpheusDomainIndex = currentBlockText.indexOf(
        '.orpheus.local:3000/items'
      );
    }

    return orpheusDomainIndex >= 0;
  }

  _makeCurrentBlockUnstyled() {
    let { editorState } = this.props;

    editorState = EditorState.push(
      editorState,
      Modifier.setBlockType(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        'unstyled'
      ),
      'change-inline-style'
    );

    this.onChange(editorState);
  }

  _makeCurrentBlockEmbed() {
    let { editorState } = this.props;
    const currentBlock = getCurrentBlock(editorState);
    const url = encodeURI(currentBlock.getText());

    editorState = insertEntity(editorState, {
      entityType: 'EMBED',
      entityData: { url },
    });

    this.onChange(editorState);
  }

  _makeCurrentBlockItem() {
    let { editorState } = this.props;
    const currentBlock = getCurrentBlock(editorState);
    const blockText = currentBlock.getText();
    const url = new URL(blockText);
    const urlParams = blockText.split('/');

    // get item id from current block url
    const hostname = (url && url.hostname) || '';
    let itemId = null;

    if (blockText.indexOf('//') > -1) {
      itemId = urlParams[4];
    } else {
      itemId = urlParams[2];
    }

    if (!itemId) {
      return false;
    }

    editorState = insertEntity(editorState, {
      entityType: 'ITEM',
      entityData: { hostname, itemId },
    });

    this.onChange(editorState);
  }

  _setFormattingTooltipContent(formattingTooltipContent) {
    this.setState(_prevState => ({
      formattingTooltipContent,
    }));
  }

  _toggleBlockquoteMode() {
    this._toggleBlockType('blockquote');
  }

  _toggleBlockType(blockType) {
    let { editorState } = this.props;

    editorState = RichUtils.toggleBlockType(editorState, blockType);

    this.onChange(editorState);
  }

  _toggleListMode() {
    this._toggleBlockType(getCurrentBlock(editorState).getType());
  }

  _uploadFile(file) {
    const { type } = file;

    if (FILE_TYPES_WHITELIST.indexOf(type) === -1) {
      return console.error(`You can't upload a ${type} file!`);
    }

    // we assign a specific onload function for each
    // file so that we have access to its name, size,
    // and type via closure
    this.fileReader.onload = e => {
      file.body = this.fileReader.result;

      const promise = this.props.handleUpload(file);

      // this.props.handleUpload() *should* return
      // a promise --- but just in case it doesn't
      if (promise) {
        promise.then(uploaded => {
          const { editorState } = this.props;
          const contentState = editorState.getCurrentContent();
          const contentStateWithEntity = contentState.createEntity(
            type,
            'IMMUTABLE',
            { src: uploaded.publicUrl }
          );
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
          const newEditorState = EditorState.set(editorState, {
            currentContent: contentStateWithEntity,
          });
          this.onChange(
            AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ')
          );
        });
      }
    };

    this.fileReader.readAsDataURL(file);
  }
}

export default OrpheusEditor;
