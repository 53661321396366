import { createMuiTheme } from '@material-ui/core';
const customTheme = createMuiTheme({
	palette: {
		primary: {
			// light: will be calculated from palette.primary.main,
			main: '#66023C'
			// dark: will be calculated from palette.primary.main,
			// contrastText: will be calculated to contrast with palette.primary.main
		},
		secondary: {
			main: '#9E9E9E'
		},
		error: {
			main: '#BC1C00'
		},
		contrastThreshold: 3,
		tonalOffset: 0.2,
	},
	props: {
		MuiButtonBase: {
			disableRipple: false,
		},
	},
	typography: {
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			'inherit',
		].join(','),
		useNextVariants: true,
		h1: {
			color: 'inherit',
			fontSize: '3rem',
			'@media (min-width:600px)': {
				fontSize: '4rem',
			},
			fontWeight: '900',
			letterSpacing: '-0.1rem',
			lineHeight: '1',
		},
		h2: {
			color: 'inherit',
			fontSize: '3rem',
			fontWeight: '700',
			letterSpacing: '-0.03rem',
			lineHeight: '1.2',
		},
		h3: {
			color: 'inherit',
			fontSize: '2.5rem',
			fontWeight: '500',
			lineHeight: '1.3',
		},
		h4: {
			color: 'inherit',
			fontSize: '2rem',
			fontWeight: '500',
			lineHeight: '1.3',
		},
		h5: {
			color: 'inherit',
			fontSize: '1.6rem',
			fontWeight: '600',
			lineHeight: '1.3',
		},
		h6: {
			color: 'inherit',
			fontSize: '1.3rem',
			fontWeight: '500',
			lineHeight: '1.3',
		},
		subtitle1: {
			color: 'inherit',
			fontSize: '0.9rem',
			fontWeight: '400',
			lineHeight: '1.5',
		},
		subtitle2: {
			color: 'inherit',
			fontSize: '0.8rem',
			fontWeight: '400',
			lineHeight: '1.2',
		},
		body1: {
			color: 'inherit',
			fontSize: '1rem',
			fontWeight: '400',
			lineHeight: '1.75',
		},
		body2: {
			color: 'inherit',
			fontSize: '0.9rem',
			fontWeight: '400',
			lineHeight: '1.4',
		},
		button: {
			color: 'inherit',
			fontSize: '0.9rem',
			fontWeight: '500',
			letterSpacing: '0.06rem',
		},
		caption: {
			color: 'inherit',
			fontSize: '0.8rem',
			fontWeight: '400',
			letterSpacing: '0.01rem',
			lineHeight: '1.3',
		},
		overline: {
			color: 'inherit',
			fontSize: '0.8rem',
			fontWeight: '400',
			letterSpacing: '0.12rem',
			lineHeight: '1',
		},
	},
	themeName: 'Orpheus Theme',
});

export default customTheme;
