import React from 'react';
import { compose } from 'react-apollo';

import Filters from '../../../../components/common/Filters';
import issuesData from '../../../../lib/issues';
import authorsData from '../../../../lib/authors';


class ArchiveFiltersContainer extends React.Component {
	constructor(props) {
		super(props);
	}

	render () {
    const issues = issuesData.data.issues;
    const authors = authorsData.data.authors;
		let filters = [];
		let _authors = [];
		let dates = [];
		let loading = false;

		if (issues) {
			issues.forEach((issue) => {
				if (issue.customFields) {
					issue.customFields.forEach((cf) => {
						if (cf.name == "Date") {
							dates.push(cf.value)
						}
					})
				}
			})
		}

		if (authors) {
			authors.forEach(author => {
				_authors.push(author.name);
			});
		}


		filters = [{
			name: 'Authors',
      type: 'string',
			values: _authors,
		}];

		if (dates && dates.length > 0) {
			filters.push({
				name: "Date",
        type: 'date',
				values: dates
			})
		}

		return (
			<Filters
				filters={filters}
				relevantFilters={filters}
			/>
		);
	}
}


export default ArchiveFiltersContainer;
