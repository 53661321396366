/**
 * @prettier
 */

import '@babel/polyfill';
import 'es6-shim';
import React from 'react';
import ReactDOM from 'react-dom';

import IssuesView from './modules/issues/components/IssuesView';
import PostView from './modules/issues/components/PostView';
import Annotations from './modules/annotations';

import registerServiceWorker from './registerServiceWorker';

registerServiceWorker();

// Use components on wordpress frontend
window.app_modules = {
	React, // Make React accessible from the base template
	ReactDOM, // Make ReactDOM accessible from the base template

	// Add all frontend views here
	IssuesView,
	PostView,
	Annotations,
};

const components = ["IssuesView", "PostView", "Annotations"];

for (let i = 0; i < components.length; i++) {
	if (document.getElementById(components[i])) {
    ReactDOM.render(
        React.createElement(window.app_modules[components[i]]),
        document.getElementById(components[i])
    );
	}
}
