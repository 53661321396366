import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

import qs from 'qs-lite';

import toggleFacet from '../../../lib/toggleFacet';
import { dateShortMonthFormat } from '../../../lib/dateHelpers';


import './Facet.css'


const Facet = ({ filterTitle, value, count, filterType, relevantFilters }) => {
	let toggled = false;

	const query = qs.parse(window.location.search.replace('?', ''));
	let selectedFilterValues = [];

	if (query[filterTitle]) {
		selectedFilterValues = query[filterTitle].split('+');
	}

	if (selectedFilterValues.some(selValue => (selValue === value))) {
		toggled = true;
	}

	const displayValue = (filterType == 'date'  ? dateShortMonthFormat(value) : value);

	let isAvailable = true;

	relevantFilters.forEach(activeFilter => {
		if (activeFilter.name === filterTitle && activeFilter.values.indexOf(value) < 0) {
			if (!toggled) {
				isAvailable = false;
			}
		}
	});

	let colorFacetId = filterTitle;
	if (value) {
		 colorFacetId += value.replace("#","");
	}


	return (
		<div
			className={`
				facet
				${!isAvailable ? '-unavailable' : ''}
				${toggled ? '-toggled' : ''}

			`}
			onClick={toggleFacet.bind(this, filterTitle, value)}
		>
			{
				!toggled && <style>{`
					 .${colorFacetId} svg { border-radius: 2px; margin: 3px; width: 18px; height: 18px; background-color: ${value}; fill: ${value}; }
				 `}</style>
			}

			{ filterTitle === 'Color' ?
				<Checkbox
					className={"facetCheckbox "+colorFacetId}
					checked={toggled}
					disabled={!isAvailable}
					style={{color: value, fill: value}}
				/>
				:
				<Checkbox
					className="facetCheckbox"
					checked={toggled}
					disabled={!isAvailable}
				/>
			}

			<Typography
				className="facetText"
			>
				{displayValue}
			</Typography>
		</div>
	);
}


Facet.propTypes = {
	value: PropTypes.string.isRequired,
	count: PropTypes.number,
};

export default Facet;
