import React from 'react';
import { compose } from 'react-apollo';
import _ from 'underscore';
import _s from 'underscore.string';

import CollectionList from '../../../../components/common/lists/CollectionList';
import issueListQuery from '../../graphql/queries/list';


const IssueListContainer = props => {
	let issues = [];

	if (
		props.issues
		&& props.issues.length
	) {
		issues = props.issues;
	}


	const _issuesFormatted = [];
	issues.forEach(issue => {
		const files = [];
    let description = '';

    issue.customFields.forEach((customField, i) => {
			if (i <= 4) {
				description += `<a href="${customField.value}">${customField.name}</a><br />`;
			}
    });

    issue.articles.forEach(article => {
      article.files.forEach(file => {
				// TODO: implement a more robust check in the future
				if (file.fileURI.endsWith('jpg')) {
	        files.push({
	          ...file,
	          type: 'image/jpeg',
	        });
				}
      });
    });


    if (issue.name !== "Uncategorized") {
  		_issuesFormatted.push({
  			_id: issue.id,
  			title: issue.title,
  			slug: _s.slugify(issue.name),
        files,
        description,
  		});
    }
	});

	return (
			<CollectionList
				collections={_issuesFormatted}
				loading={props.loading}
			/>
	);
};

export default compose(
  issueListQuery,
)(IssueListContainer);
