import React from 'react';
import mime from 'mime-types';

import Root from '../../../../containers/Root';
import MediaViewer from '../../../../components/common/MediaViewer';
import PostContainer from '../../containers/PostContainer';

const issue = {
  "articles": [
    {
      "files": []
    },
    {
      "files": [
        {
          "id": 8312,
          "fileURI": "http://journal.oraltradition.org/wp-content/uploads/files/ecompanions/32i/full/Poupard-1.jpg"
        },
        {
          "id": 8313,
          "fileURI": "http://journal.oraltradition.org/wp-content/uploads/files/ecompanions/32i/full/Poupard-2.jpg"
        },
        {
          "id": 8314,
          "fileURI": "http://journal.oraltradition.org/wp-content/uploads/files/ecompanions/32i/full/Poupard-3.jpg"
        },
        {
          "id": 8315,
          "fileURI": "http://journal.oraltradition.org/wp-content/uploads/files/ecompanions/32i/full/Poupard-4.jpg"
        },
        {
          "id": 8316,
          "fileURI": "http://journal.oraltradition.org/wp-content/uploads/files/ecompanions/32i/full/Poupard-5.jpg"
        },
        {
          "id": 8317,
          "fileURI": "http://journal.oraltradition.org/wp-content/uploads/files/ecompanions/32i/full/Poupard-6.jpg"
        },
        {
          "id": 8318,
          "fileURI": "http://journal.oraltradition.org/wp-content/uploads/files/ecompanions/32i/full/Poupard-7.jpg"
        },
        {
          "id": 8319,
          "fileURI": "http://journal.oraltradition.org/wp-content/uploads/files/ecompanions/32i/full/Poupard-8.jpg"
        }
      ]
    },
    {
      "files": []
    },
    {
      "files": []
    },
    {
      "files": []
    },
    {
      "files": [
        {
          "id": 8296,
          "fileURI": "http://journal.oraltradition.org/wp-content/uploads/files/ecompanions/32i/full/Drout_Smith-1.jpg"
        },
        {
          "id": 8297,
          "fileURI": "http://journal.oraltradition.org/wp-content/uploads/files/ecompanions/32i/full/Drout_Smith-2.jpg"
        },
        {
          "id": 8298,
          "fileURI": "http://journal.oraltradition.org/wp-content/uploads/files/ecompanions/32i/full/Drout_Smith-3.jpg"
        },
        {
          "id": 8299,
          "fileURI": "http://journal.oraltradition.org/wp-content/uploads/files/ecompanions/32i/full/Drout_Smith-4.jpg"
        },
        {
          "id": 8300,
          "fileURI": "http://journal.oraltradition.org/wp-content/uploads/files/ecompanions/32i/full/Drout_Smith-5.jpg"
        },
        {
          "id": 8301,
          "fileURI": "http://journal.oraltradition.org/wp-content/uploads/files/ecompanions/32i/full/Drout_Smith-6.jpg"
        },
        {
          "id": 8302,
          "fileURI": "http://journal.oraltradition.org/wp-content/uploads/files/ecompanions/32i/full/Drout_Smith-7.jpg"
        },
        {
          "id": 8303,
          "fileURI": "http://journal.oraltradition.org/wp-content/uploads/files/ecompanions/32i/full/Drout_Smith-8.jpg"
        }
      ]
    }
  ]
};

/**
const files = [];
issue.articles.forEach(article => {
	article.files.forEach(file => {
		let fileExtension = file.fileURI.split('.').pop();
		files.push({
			type: mime.lookup(file.fileURI),
			name: file.fileURI,
			path: file.fileURI,
		});
	});
});
*/
window.__orpheus__ = window.__orpheus__ || {};
const _files_raw = window.__orpheus__.eCompanionFiles || [];
const files = [];

console.log(_files_raw);
console.log(_files_raw);
console.log(_files_raw);
console.log(_files_raw);

_files_raw.forEach(file => {
	let fileExtension = file.fileURI.split('.').pop();
	files.push({
		type: mime.lookup(file.fileURI),
		name: file.fileURI,
		path: file.fileURI,
	});
});

const PostView = () => (
  <Root>
    {files.length > 0 ? (
      <MediaViewer
        files={files}
        editMode={false}
        thumbnailSize={1200}
      />
    )
  :
    <p>No files were found for this eCompanion.</p>
  }
  </Root>
);

export default PostView;
