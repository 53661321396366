import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import $ from 'jquery';
import autoBind from 'react-autobind';

import SelectedFilters from '../SelectedFilters';
import Filter from '../Filter';


// TODO:
// split filter mocks into FiltersContainer

import './Filters.css';

const toggleFilters = (e) => {
	$(document.body).toggleClass('-showMobileFilters');
};

const applyFilters = (e) => {
	$(document.body).removeClass('-showMobileFilters');
};

class Filters extends React.Component {

	constructor(props) {
		super(props);
		autoBind(this);
	}
	render () {

	 	const { filters, classes, loading, relevantFilters } = this.props;
		const _classes = classes || [];

		_classes.push('filters');

		if (loading) {
			_classes.push('-loading');
		}

		return (
			<div className={_classes.join(' ')}>
				{loading ? (
					<div className="filtersLoading">
						<div className="filtersLoadingInner"></div>
					</div>
				) : (
					<div>
						<div className="filtersToggle" onClick={toggleFilters}>
							<Typography variant="button">
								Filter
							</Typography>
						</div>
						<div className="filtersContent">
							<SelectedFilters />
							{filters.map(filter => {

								// do not show filters with no facets
								if (!filter.values || !filter.values.length) {
									return null;
								}

								// do not show filters for transcription
								if (filter.name === "Transcription") {
									return null;
								}

								return (
									<Filter filter={filter} key={filter.name} relevantFilters={relevantFilters} />
								)
							})}
						</div>
						<div className="filtersApply" onClick={applyFilters}>
							<Typography variant="button">
								Show items
							</Typography>
						</div>
					</div>
				)}
			</div>
		);
	}
}

Filters.propTypes = {
	filters: PropTypes.array,
};

Filters.defaultProps = {
	filters: [],
};

export default Filters;
