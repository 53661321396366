import React from 'react';
import Annotation from '../Annotation';

class Overlay extends React.Component {

	constructor(props) {
		super(props);
		const { getImageViewport } = this.props;
		this.state = {
			viewportSize: getImageViewport()
		};
	}

	componentDidMount() {
		const { openSeadragon } = this.props;
		openSeadragon.addHandler('animation', this.handleResize);
	}

	componentWillUnmount() {
		const { openSeadragon } = this.props;
		openSeadragon.removeAllHandlers('animation');
	}

	handleResize = () => {
		const { getImageViewport } = this.props;
		this.setState({
			viewportSize: getImageViewport()
		});
	};

	render() {
		const { shapeList } = this.props;
		const { viewportSize } = this.state;
		const svgStyle = {
			position: 'absolute',
			display: 'block',
			left: viewportSize.originX,
			top: viewportSize.originY,
			width: viewportSize.width,
			height: viewportSize.height
		};

		return (
			<svg
				id="annotation-overlay"
				xmlns="http://www.w3.org/2000/svg"
				version="1.1"
				preserveAspectRatio="none"
				viewBox="0 0 100 100"
				width="100%"
				height="100%"
				style={svgStyle}
			>
				{shapeList.map((shape, index) => {
					return <Annotation
						key={index}
						index={index}
						points={shape}
						shape={shape}
						viewportSize={this.state.viewportSize}
						annotation={shape}
						annotationMouseOver={this.props.annotationMouseOver}
						isActive={index == this.props.activeAnnotationI}
						setActiveAnnotationI={this.props.setActiveAnnotationI} />;
				})}
			</svg>
		);
	}
}

export default Overlay;
