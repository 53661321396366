import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import autoBind from 'react-autobind';

class Annotation extends React.Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}
	handleClick(e) {
			e.stopPropagation();
		this.props.setActiveAnnotationI(this.props.index)
	}

	handleMouseOver() {
		this.props.annotationMouseOver(true)
	}
	handleMouseOut() {
		this.props.annotationMouseOver(false)

	}
	render() {
		const { shape, viewportSize, annotation, isActive } = this.props;
		//todo (daniel) fix type hack

		const x = parseFloat(shape.x)
		const y = parseFloat(shape.y)

		// console.log("===== annotation ======")
		// console.log("x: ", x)
		// console.log("y: ", y)
		// console.log("viewportSize: ", viewportSize)

		return (
			<g>
			<circle
				fill="white"
				fillOpacity="0"
			
				r={1 / viewportSize.scale}
				cx={x}
				cy={y}
				onClick={this.handleClick}
				className={`${isActive ? ' active ' : ''}`}
				onMouseOver={this.handleMouseOver}
				onMouseOut={this.handleMouseOut}
			/>
			</g>

		);
	}
}
export default Annotation;
