import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

import './FileUploader.css';
import CloudUpload from '@material-ui/icons/CloudUpload';


export default class FileUploader extends React.Component {
	constructor(props) {
		super(props);

		this.handleDrop = this.handleDrop.bind(this);

		this.state = {
			uploading: false,
		};
	}

	handleDrop(acceptedFiles, rejectedFiles) {
		this.props.handleAddFiles(acceptedFiles);
	}

	render() {
		return (
			<div className="fileUploader">
				<Dropzone
					className="fileUploaderDropzone"
					onDrop={this.handleDrop}
				>
					<div className="fileUploaderContent">
						{this.state.uploading ? (
							<label>Uploading...</label>
						) : (
							<div>
								<CloudUpload />
								<h6>Drag and drop to upload</h6>
								<p>or <span>browse</span> to choose a file</p>
							</div>
						)}
					</div>
				</Dropzone>
			</div>
		);
	}
}
FileUploader.propTypes = {
	handleAddFiles: PropTypes.func.isRequired
};
