import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import prune from 'underscore.string/prune';

import './CollectionListItem.css';


const renderImageFiles = (loading, files, collectionUrl) => {
	const _files = [];
	let images;
	let imagesLayout = '';

	if (loading) {
		return (
			<div className="collectionListItemImages collectionListItemImagesLoading collectionListItemImages-quadruple">
				<div className="collectionListItemImages-Left">
					<div className="collectionListItemImage collectionListItemImage-1" />
					<div className="collectionListItemImages-Left-Lower">
						<div className="collectionListItemImage collectionListItemImage-2" />
						<div className="collectionListItemImage collectionListItemImage-3" />
					</div>
				</div>
				<div className="collectionListItemImages-Right">
					<div className="collectionListItemImage collectionListItemImage-4" />
				</div>
			</div>
		);
	}

	if (!files || !files.length) {
		return null;
	}

	files.forEach(file => {
		const isImage = file.type.slice(0, file.type.indexOf('/')) === 'image';
		if (isImage) {
			_files.push(file);
		}
	});

	if (!_files.length) {
		return null;
	}

	if (files.length === 1) {
		imagesLayout = 'single';
		images = (
			<React.Fragment>
				<div
					className="collectionListItemImage collectionListItemImage-1"
					style={{
        		backgroundImage: `url(//iiif.orphe.us/${_files[0].name}/full/full/0/default.jpg)`,
        	}}
				/>
			</React.Fragment>
		);
	} else if (files.length === 2) {
		imagesLayout = 'double';
		images = (
			<React.Fragment>
				<div
					className="collectionListItemImage collectionListItemImage-1"
					style={{
        		backgroundImage: `url(//iiif.orphe.us/${_files[0].name}/full/full/0/default.jpg)`,
        	}}
				/>
				<div
					className="collectionListItemImage collectionListItemImage-2"
					style={{
        		backgroundImage: `url(//iiif.orphe.us/${_files[1].name}/full/full/0/default.jpg)`,
        	}}
				/>
			</React.Fragment>
		);
	} else if (files.length === 3) {
		imagesLayout = 'triple';
		images = (
			<React.Fragment>
				<div className="collectionListItemImagesLeft">
					<div
						className="collectionListItemImage collectionListItemImage-1"
						style={{
  						backgroundImage: `url(//iiif.orphe.us/${_files[0].name}/full/full/0/default.jpg)`,
  					}}
        	/>
					<div
						className="collectionListItemImage collectionListItemImage-2"
						style={{
  						backgroundImage: `url(//iiif.orphe.us/${_files[1].name}/full/full/0/default.jpg)`,
  					}}
        	/>
				</div>
				<div className="collectionListItemImagesRight">
					<div
						className="collectionListItemImage collectionListItemImage-3"
						style={{
  						backgroundImage: `url(//iiif.orphe.us/${_files[2].name}/full/full/0/default.jpg)`,
  					}}
        	/>
				</div>
			</React.Fragment>
		);
	} else {
		imagesLayout = 'quadruple';
		images = (
			<React.Fragment>
				<div className="collectionListItemImages-Left">
					<div
						className="collectionListItemImage collectionListItemImage-1"
						style={{
  						backgroundImage: `url(//iiif.orphe.us/${_files[0].name}/full/full/0/default.jpg)`,
  					}}
        	/>
					<div className="collectionListItemImages-Left-Lower">
						<div
							className="collectionListItemImage collectionListItemImage-2"
							style={{
    						backgroundImage: `url(//iiif.orphe.us/${_files[1].name}/full/full/0/default.jpg)`,
    					}}
        		/>
						<div
							className="collectionListItemImage collectionListItemImage-3"
							style={{
    						backgroundImage: `url(//iiif.orphe.us/${_files[2].name}/full/full/0/default.jpg)`,
    					}}
        		/>
					</div>
				</div>
				<div className="collectionListItemImages-Right">
					<div
						className="collectionListItemImage collectionListItemImage-4"
						style={{
  						backgroundImage: `url(//iiif.orphe.us/${_files[3].name}/full/full/0/default.jpg)`,
  					}}
        	/>
				</div>
			</React.Fragment>
		);
	}

	return (
		<div className={`collectionListItemImages collectionListItemImages-${imagesLayout}`}>
			<a href={collectionUrl}>
				{images}
			</a>
		</div>
	)
};


const CollectionListItem = ({ _id, title, slug, description, files, loading }) => {
	const collectionUrl = `/collections/${_id}/${slug}`;

	return (
		<div className={`collectionListItem ${loading ? 'loading' : ''}`}>
			<div className="collectionListItemText">
				<a href={collectionUrl}>
					<h3>{prune(title, 120)}</h3>
				</a>
				<p>
					{prune(description, 500)}
				</p>
			</div>

			{renderImageFiles(loading, files, collectionUrl)}
		</div>
	);
};

CollectionListItem.propTypes = {
	_id: PropTypes.number,
	title: PropTypes.string,
	slug: PropTypes.string,
	files: PropTypes.array,
	description: PropTypes.string,
};

export default CollectionListItem;
