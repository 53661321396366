import React from 'react';
import PropTypes from 'prop-types';
import Sticky from 'react-stickynode';

import Root from '../../../../containers/Root';
import IssueFilters from './IssueFilters';
import Issues from './Issues';


import '../../../../components/common/FacetedCards/FacetedCards.css';


class FacetedCards extends React.Component {
	constructor(props) {
		super(props);
	}

	render () {
		const _classes = this.props.classes || [];

		_classes.push('facetedCards');

		if (this.props.loading) {
			_classes.push('-loading');
		}

		return (
			<div className={_classes.join(' ')}>
				<div className="facetedCardsContent">
					<Sticky
						className="facetedCardsContentFilters"
						activeClass="-sticky"
						bottomBoundary=".sticky-reactnode-boundary"
						enabled
					>
						<div className="facetedCardsContentFiltersInner">
              <IssueFilters />
						</div>
					</Sticky>
					<div className="facetedCardsContentCards">
            <Issues />
					</div>
				</div>
			</div>
		);
	}
}

FacetedCards.propTypes = {
	theme: PropTypes.string,
	selectable: PropTypes.bool,
}

FacetedCards.defaultProps = {
	theme: '',
};


const IssuesView = () => (
  <Root>
    <FacetedCards />
  </Root>
);

export default IssuesView;
