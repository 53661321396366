/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import MetadataFieldMapInput from '../MetadataFieldMapInput';

ItemMetaFieldMap.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
};

function ItemMetaFieldMap({ value = '{}', label }) {
	let initialValue;

	try {
		initialValue = JSON.parse(value);
	} catch (e) {
		console.error('Unable to parse map value:', value);
	}

	return (
		<div className="itemMetaField">
			<div className="itemMetaFieldLabel">
				<Typography variant="caption">{label}</Typography>
			</div>
			<div className="itemMetaFieldValue -map">
				<MetadataFieldMapInput
					initialValue={initialValue}
					interactive={false}
				/>
			</div>
		</div>
	);
}

export default ItemMetaFieldMap;
