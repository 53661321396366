/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import ItemMetaFieldItemListContainer from '../../containers/ItemMetaFieldItemListContainer';

import './ItemMetaFieldItem.css';

ItemMetaFieldItem.propTypes = {
	label: PropTypes.string,
};

function ItemMetaFieldItem({ label, value }) {
	let items = [];
	let itemIds = [];
	if (value) {
		try {
			items = JSON.parse(value);
			items.forEach(item => {
				if ('value' in item) {
					itemIds.push(item.value);
				} else if ('_id' in item) {
					itemIds.push(item._id);
				}
			});
		} catch (e) {
			try {
				items = JSON.parse(items);
				items.forEach(item => {
					if ('value' in item) {
						itemIds.push(item.value);
					} else if ('_id' in item) {
						itemIds.push(item._id);
					}
				});
			} catch (e) {
				try {
					items = JSON.parse(items);
					items.forEach(item => {
						if ('value' in item) {
							itemIds.push(item.value);
						} else if ('_id' in item) {
							itemIds.push(item._id);
						}
					});
				} catch (e) {
					try {
						items = JSON.parse(items);
						items.forEach(item => {
							if ('value' in item) {
								itemIds.push(item.value);
							} else if ('_id' in item) {
								itemIds.push(item._id);
							}
						});
					} catch (e) {
						try {
							items = JSON.parse(items);
							items.forEach(item => {
								if ('value' in item) {
									itemIds.push(item.value);
								} else if ('_id' in item) {
									itemIds.push(item._id);
								}
							});
						} catch (e) {
							if (typeof value === 'object') {
								itemIds = value.map((v) => {
									if ('value' in v) {
										return v.value;
									} else if ('_id' in v) {
										return v._id;
									}
								});
							} else {
								itemIds = value;
							}
						}
					}
				}
			}
		}
	}


	return (
		<div className="itemMetaField">
			<div className="itemMetaFieldLabel">
				<Typography variant="caption">{label}</Typography>
			</div>
			<div className="itemMetaFieldValue">
				<ItemMetaFieldItemListContainer ids={itemIds} />
			</div>
		</div>
	);
}

export default ItemMetaFieldItem;
