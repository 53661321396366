import React from 'react';
import { Link } from 'react-router-dom';
import './Tag.css';

const Tags = props => (
	<Link
		to={`/search?Tags=${props.tag}&page=1`}
	>
		<span className="tag">
			{props.tag}
		</span>
	</Link>
);


export default Tags;
