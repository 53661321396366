import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import $ from 'jquery';
import autobind from 'react-autobind';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { debounce } from 'lodash';

import muiTheme from '../../lib/muiTheme';
import Header from '../Header';
import UserModal from '../Authentication/UserModal';
import AnnotationDrawer from '../AnnotationDrawer';
import AnnotationIcon from '../AnnotationIcon';
import Utils from '../../lib/utils';
import getCurrentPathname from '../../lib/getCurrentPathname';


class ReadingEnvironment extends React.Component {
	constructor(props) {
		super(props);
		let bookmarks = [];

		if (typeof localStorage !== 'undefined') {
			bookmarks = localStorage.getItem('bookmarks');
			if (!bookmarks) {
				bookmarks = [];
				localStorage.setItem('bookmarks', bookmarks);
			} else {
				bookmarks = JSON.parse(bookmarks);
			}
		}

		this.state = {
			activeParagraph: null,
			annotationDrawerOpen: false,
			annotationDrawerCreateMode: false,
			annotationDrawerParagraph: null,
			bookmarks,
			userPanelVisible: false,
			signInVisible: true,
		};

		autobind(this);
	}

	getChildContext = () => {
		return { muiTheme: getMuiTheme(muiTheme) };
	}

	componentDidMount = () => {
		const legacyMode = Cookies.get('legacyMode');

		$('.pageOverlay').on('click', () => this.closeAnnotationDrawer());
		$('body').on('mouseover', this.handleMouseMove.bind(this));
		window.addEventListener('scroll', debounce(this.savePosition, 1000));

		if (legacyMode === 'true') {
			$('body').removeClass('readingEnvironment');
			$('.mainDisplay .rightDisplay').removeClass('readingContent').removeClass('readingContent--primary');
		} else {
			this.addReadingClasses();
		}

		setTimeout(() => {
			this.checkParaLinkAndRecentPositions();
		}, 1000);
	}

	componentWillUnmount = () => {
		window.removeEventListener('scroll', this.savePosition.bind(this));

		$('body')
			.removeClass('readingEnvironment');
		$('#main')
			.removeClass('readingContent')
			.removeClass('readingContent--primary');
	}

	addReadingClasses = () => { // eslint-disable-line
		$('body')
			.addClass('readingEnvironment');
		$('#main')
			.addClass('readingContent')
			.addClass('readingContent--primary');
	}

	openAnnotationDrawer = (paragraph, createMode) => {
		const isCreateMode = createMode;
		$('.readingContent.readingContent--primary').addClass('annotations-visible');

		console.log(paragraph);

		setTimeout(() => {
			this.setState({
				annotationDrawerOpen: true,
				annotationDrawerCreateMode: isCreateMode,
				annotationDrawerParagraph: paragraph,
			});
		}, 100);
	}

	closeAnnotationDrawer = () => {
		$('.readingContent.readingContent--primary').removeClass('annotations-visible');

		this.setState({
			annotationDrawerOpen: false,
			annotationDrawerCreateMode: false,
			annotationDrawerParagraph: null,
		});
	}

	toggleBookmark = (paragraph) => {
		let { bookmarks } = this.state;
		let paragraphIsBookmarked = false;

		if (!bookmarks) {
			bookmarks = [];
		}

		bookmarks.forEach((bookmark) => {
			if (paragraph.i === bookmark.paragraphN) {
				paragraphIsBookmarked = true;
			}
		});

		if (paragraphIsBookmarked) {
			bookmarks = bookmarks.filter(bookmark =>
				bookmark.paragraphN !== paragraph.i
			);
		} else {
			bookmarks.push({
				paragraphN: paragraph.i,
				path: getCurrentPathname(),
			});
		}

		localStorage.setItem('bookmarks', JSON.stringify(bookmarks));
		this.setState({ bookmarks });
	}

	handleMouseMove = (e) => {
		const mouseY = e.pageY;
		const activeParagraphs = [];
		const { activeParagraph } = this.state;
		const paragraphs = this.getParagraphs();

		paragraphs.forEach((p) => {
			if (p.yOffset < mouseY && mouseY < (p.yOffset + p.height)) {
				activeParagraphs.push(p.i);
			}
		});

		const _activeParagraph = activeParagraphs.pop();
		this.setState({
			activeParagraph: _activeParagraph,
		});
	}

	getParagraphs = () => {
		const $paraElems = $('#main').find('p');
		const paragraphs = [];

		$paraElems.each((i, elem) => {
			paragraphs.push({
				i,
				yOffset: $(elem).offset().top,
				xOffset: $(elem).offset().left,
				height: $(elem).height(),
				width: $(elem).width(),
			});
		});

		return paragraphs;
	}

	toggleUserModal = (signInVisible) => {
		this.setState({
			userPanelVisible: !this.state.userPanelVisible,
			signInVisible,
		});
	}

	openUserModal = (signInVisible) => {
		this.setState({
			userPanelVisible: true,
			signInVisible,
		});
	}

	closeUserModal = () => {
		this.setState({
			userPanelVisible: false,
		});
	}

	checkParaLinkAndRecentPositions = () => {
		const loggedInUser = this.props.getAuthedUserQuery.getAuthedUser;
		const paraRef = Utils.paramStringToObject(window.location.search.substring(1));
		let position;

		if (!loggedInUser) {
			return false;
		}

		loggedInUser.recentPositions.forEach((recentPosition) => {
			if (recentPosition.link === getCurrentPathname()) {
				position = recentPosition;
			}
		});

		if ('paragraph' in paraRef) {
			position = {
				activeElem: paraRef.paragraph
			};
		}

		if (position) {
			const $paraElems = $('#main').find('p');
			const paraElemsDepths = [];

			$paraElems.each((i, elem) => {
				paraElemsDepths.push($(elem).offset().top);
			});

			$('html, body').animate({
				scrollTop: paraElemsDepths[position.activeElem] - 60, // minus offset for header
			}, 100);
		}
	}

	savePosition = () => {
		const book = this.props.bookQuery.bookByChapter;
		const token =	Cookies.get('loginToken');

		if (!token || !book) {
			return false;
		}

		const position = {};
		const $paraElems = $('#main').find('p');
		const depth = $(window).scrollTop() + 210; // plus an offset for the header
		const paraElemsDepths = [];

		$paraElems.each((i, elem) => {
			paraElemsDepths.push($(elem).offset().top);
		});

		paraElemsDepths.forEach((paraDepth, i) => {
			if (
					typeof paraElemsDepths[i + 1] !== 'undefined'
				&& paraDepth < depth
				&& depth < paraElemsDepths[i + 1]
			) {
				position.activeElem = i;
			}
		});

		position.title = book.title;
		position.author = book.author;
		position.link = getCurrentPathname();
		book.chapters.forEach((chapter) => {
			if (getCurrentPathname() === chapter.url) {
				position.subtitle = chapter.title;
			}
		});

		// if there is an active paragraph element from the reading env
		if (typeof position.activeElem !== 'undefined') {
			// update the recnet items position
			this.props.userUpdatePosition(position);
		}
	}

	renderIcons = () => {
		const self = this;
		const annotations = this.props.annotationQuery.annotations;
		const loggedInUser = this.props.getAuthedUserQuery.getAuthedUser;
		const book = this.props.bookQuery.bookByChapter;
		const { activeParagraph, bookmarks } = this.state;
		let userAuthenticatedToAnnotate = false;
		const paragraphs = this.getParagraphs();

		if (book && loggedInUser) {
			// TODO: determine if user is authorized to annotate
			if (loggedInUser.canAnnotateBooks && ~loggedInUser.canAnnotateBooks.indexOf(book._id)) {
				userAuthenticatedToAnnotate = true;
			}
		}

		return paragraphs.map((p, i) => {
			const users = [];
			const count = 0;
			let isActive = false;
			let isBookmarked = false;

			if (annotations) {
				annotations.forEach((annotation) => {
					if (p.i === annotation.paragraphN) {
						annotation.users.forEach((user) => {
							if (!~users.indexOf(user)) {
								users.push(user);
							}
						});
					}
				});
			}

			if (bookmarks) {
				bookmarks.forEach((bookmark) => {
					if (p.i === bookmark.paragraphN) {
						isBookmarked = true;
					}
				});
			}

			if (activeParagraph === p.i) {
				isActive = true;
			}

			return (
				<AnnotationIcon
					key={i}
					paragraph={p}
					userIds={users}
					paragraphIsActive={isActive}
					paragraphIsBookmarked={isBookmarked}
					openAnnotationDrawer={self.openAnnotationDrawer}
					toggleBookmark={self.toggleBookmark}
					loggedInUser={loggedInUser}
					userAuthenticatedToAnnotate={userAuthenticatedToAnnotate}
				/>
			);
		});
	}

	render = () => {
		const { annotationDrawerOpen, annotationDrawerCreateMode, annotationDrawerParagraph } = this.state;
		const annotations = this.props.annotationQuery.annotations;
		const book = this.props.bookQuery.bookByChapter;
		const tenant = this.props.tenantQuery.tenantBySubdomain;
		const loggedInUser = this.props.getAuthedUserQuery.getAuthedUser;

		const styles = {
			loginButton: {
				top: (document.body.offsetHeight - 45),
			},
		};

		return (
			<div
				className="readingEnvironment"
			>
				<Header
					book={book}
					tenant={tenant}
					loggedInUser={loggedInUser}
					openUserModal={this.openUserModal}
					closeUserModal={this.closeUserModal}
				/>

				{/* div used to display annotationIcons */}
				<div
					className={`annotationIcons
						${annotationDrawerOpen ? 'annotationIconsHidden' : ''}
					`}
				>
					{this.renderIcons()}
				</div>

				{/* div used to display the drawer */}
				<AnnotationDrawer
					open={annotationDrawerOpen}
					book={book}
					createMode={annotationDrawerCreateMode}
					paragraph={annotationDrawerParagraph}
					annotations={annotations}
					closeAnnotationDrawer={this.closeAnnotationDrawer}
					loggedInUser={loggedInUser}
					toggleUserModal={this.toggleUserModal}
				/>

				{/* pageOverlay for handling click */}
				<div
					className={`pageOverlay
						${annotationDrawerOpen ? 'pageOverlayActive' : ''}
					`}
					onClick={this.closeAnnotationDrawer.bind(this)}
				/>

				<UserModal
					visible={this.state.userPanelVisible}
					signInVisible={this.state.signInVisible}
					openUserModal={this.openUserModal}
					closeUserModal={this.closeUserModal}
				/>
				{/*
				{!loggedInUser ?
					<a
						className="authenticationOption login footerLoginButton"
						onClick={this.toggleUserModal.bind(this, true)}
						style={styles.loginButton}
					>
						Annotations Login
					</a>
				: ''}
				*/}
			</div>
		);
	}
}

ReadingEnvironment.childContextTypes = {
	muiTheme: PropTypes.object,
};

export default ReadingEnvironment;
