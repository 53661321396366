/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

import List from '../../../../components/common/lists/List';

import './ItemMetaFieldItemList.css';

const ItemMetaFieldItemList = ({ items }) => (
	<div className="itemMetaFieldItemList">
		<List items={items} />
	</div>
);

ItemMetaFieldItemList.propTypes = {
	items: PropTypes.array.isRequired,
};

export default ItemMetaFieldItemList;
