import React from 'react';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player'
import OpenSeadragon from 'openseadragon';
import autoBind from 'react-autobind';
import S3Upload from 'react-s3-uploader/s3upload';
import shortid from 'shortid';
import $ from 'jquery';

import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import IconClose from '@material-ui/icons/Close';
import IconComment from '@material-ui/icons/Comment';
import IconDescription from '@material-ui/icons/Description';
import IconFullscreenExit from '@material-ui/icons/FullscreenExit';
import IconEdit from '@material-ui/icons/Edit';
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from '@material-ui/core/Dialog';

import FileViewer from '../FileViewer';

import './MediaModal.css';


class MediaModal extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			fileTitle: '',
			file: false,
			modalOpened: false,
			thumbnailsOpen: true,
			thumbnailFocus: true,
			drawMode: false,
			showAnnotations: true,
      menuMouseOver: false,
		};
		autoBind(this);
	}


	toggleShowAnnotations = () => {
		this.setState({showAnnotations: !this.state.showAnnotations})
	}

	handleEscape = (event) => {
		if(event.keyCode === 27 && this.props.detailModalOpen) {
			this.handleClose()
		}
	}

	componentDidMount = () => {
		if(this.props.file) {
			this.setState({file: this.props.file})
		}

		if (this.props.files && this.props.files.length === 1) {
			this.setState({thumbnailsOpen: false});
		}

		document.addEventListener("keydown", this.handleEscape, false);
	}

	componentWillUnmount = () => {
		document.removeEventListener("keydown", this.handleEscape, false);
	}

	componentDidUpdate = (prevProps) => {
		if (!prevProps.file && this.props.file) {
			this.setState({file: this.props.file});

		}
	}

	turnOffDrawMode = () => {
		this.setState({
			drawMode: false
		})
	}


	toggleDrawMode = () => {
		if (!this.state.drawMode) {
			this.setState({
				showAnnotations: true,
				thumbnailsOpen: false
			})
		}

		this.setState({
			drawMode: !this.state.drawMode
		})
	}

	toggleThumbnails = () => {
		this.setState({
			thumbnailsOpen: !this.state.thumbnailsOpen
		}, () => {
			window.dispatchEvent(new Event('resize'));

		});
		// todo (Daniel) fix it so the timeouts are unnecessary

		setTimeout(()=>{
			this.setFile(this.state.file);
		}, 500)

	}

	setFile = (file) => {
    if (!file) {return;}
		let fileTitle = (file.name && file.name.replace(file._id+'-', '') !== file.title ) ? file.title : '';
		this.setState({file, thumbnailFocus: true, fileTitle: fileTitle});
	}

	handleClose = () => {
		this.setState({modalOpened: false, thumbnailsOpen: true}, () => {this.props.handleClose();});
	}

  handleMouseOver = () => {
    this.setState({menuMouseOver: true})
  }
  handleMouseOut = () => {
    this.setState({menuMouseOver: false})
  }

	renderThumbnail = (file) => {
		if (!file.path && !file.name.includes('oral-tradition.chs')) {
			return <div className={`fileThumbnail loadingThumbnail`}></div>;
		}

		let thumbnail = null;
		let type = '';
		let url = '';

		if (file && file.path && file.type) {

			if (file.type.indexOf("image") >= 0) {
				let path = `//iiif.orphe.us/${file.name}/square/500,/0/default.jpg`;
				if (window.location.hostname.includes('oraltradition')) {
					path = file.name;
				}
				type = 'image';
				thumbnail = (<img className='-loading'
					src={path} alt={file.title}
					onLoad={(event)=>{
						event.target.classList.remove("-loading")
					}}
					onError={(event)=>{
						event.target.classList.remove("-loading")
						event.target.classList.add("-error")
					}}
					/>
				);

			} else if (file.type.indexOf("video") >= 0) {
				type = 'video';
				// url = `https://s3.amazonaws.com/iiif-orpheus/${file.name}`;
				url = file.name;
				thumbnail = (
					<div className="videoThumbnail">
						<ReactPlayer
							url={url}
							width="200px"
							height="200px"
						/>
					</div>
				);
			} else if (file.type.indexOf("audio") >= 0) {
				type = 'audio';
				// url = `https://s3.amazonaws.com/iiif-orpheus/${file.name}`;
				url = file.name;

				thumbnail = (
					<div className="audioThumbnail">
						<ReactPlayer
							url={url}
							controls
							width="200px"
							height="200px"
						/>
					</div>
				);
			} else if (file.type.indexOf("pdf") >= 0) {
				type = 'pdf';
				thumbnail = (
					<div className="pdfThumbnail">
						<IconDescription />
						<span className="pdfLabel">PDF</span>
					</div>
				);
			}
		}

		return thumbnail;
	}

	handleZoom = (thumbnailFocus) => {
		this.setState({thumbnailFocus});
	}

	handleKeyPress = (event) => {
		const files = this.props.files;
		const file = this.state.file;
		if (!this.state.thumbnailFocus) {
			return;
		}

		let currentFileI = 0;
		let nextFileI = 0;
		files.forEach((f, i) => {
			if (f._id == file._id) {
				currentFileI = i;
			}
		})

		if (event.key == 'ArrowRight' || event.key == 'ArrowDown') {

			nextFileI = currentFileI + 1;
			if (nextFileI === files.length) {
				nextFileI = files.length -1;
			}
		} else if (event.key == 'ArrowLeft' || event.key == 'ArrowUp') {
			nextFileI = currentFileI - 1;
			if (nextFileI < 0) {
				nextFileI = 0;
			}
		} else {
			return;
		}

		const nextFile = files[nextFileI];
		this.setFile(nextFile, true);

		const thumbnailId = `thumbnail_${nextFile._id}`;
		if (window.innerWidth <= 800) {
			const thumbnail = document.getElementById(thumbnailId);
			if (thumbnail) {
				const leftPos = thumbnail.offsetLeft;
				$("#thumbnailContainer").animate({ scrollLeft: leftPos - 40 }, 250);
			}
		} else {
			const thumbnail = document.getElementById(thumbnailId);
			if (thumbnail) {
				const topPos = thumbnail.offsetTop;
				$("#thumbnailContainer").animate({ scrollTop: topPos - 40 }, 250);
			}
		}
	}

	handleRemoveFile = (fileIndex) => {
		const files = this.props.files;

		// remove the file
		this.props.removeFile(fileIndex);

		// set new image (todo daniel fix this)
		let newIndex = fileIndex;
		if (fileIndex + 1 > this.props.files.length - 1) {
			newIndex = fileIndex - 1;
		}
		let newFile = this.props.files[newIndex]
		setTimeout(
			() => {		this.setFile(this.props.files[newIndex]);}, 100
		)

		// close the modal if no files
		if (this.props.files.length < 2) {
			this.handleClose()
		}
	}

	render = () => {
		const file = this.state.file
		let _classes = [];
		_classes.push( (this.state.thumbnailFocus ? 'thumbnailFocus zoomedOut' : 'zoomedIn') )
		_classes.push( (this.state.thumbnailsOpen ? 'thumbnailsOpen' : 'thumbnailsClosed') )

    let files = this.props.files.map((file) => {
      file._id = file._id || shortid.generate();
      return file;
    })


		return (
			<Dialog
				open={this.props.detailModalOpen || false}
				onClose={this.handleClose}
				className={_classes.join(' ')}
				fullScreen
				onKeyDown={this.handleKeyPress}
			>

				<div className="MediaModalThumbnailToggle" onClick={this.toggleThumbnails}>
					<div className="MediaModalThumbnailToggleThumb"></div>
				</div>

				<div className="MediaModal">
					{this.state.thumbnailsOpen && <ul id='thumbnailContainer' className="fileDetailThumbnails">
						{files.map((file, fileIndex) => {
							if (!file) {
								return "";
							}
							return (<li key={`thumbnail_${fileIndex}`}
									id={`thumbnail_${file._id}`}
									className={`${(file._id && this.state.file._id == file._id) ? '-selected' : ''}`}
									onClick={()=>{this.setFile(file)}}>
									{this.props.removeFile &&
										<div className="fileDetailsButtons">
											<button
												className="fileDetailsDeleteFile"
												onClick={() => {
													this.handleRemoveFile(fileIndex);
												}}
											>
												<IconClose />
											</button>
										</div>

									}
									{this.renderThumbnail(file)}
							</li>)
						})}
					</ul>}

					<FileViewer
						file={file}
						handleZoom={this.handleZoom}
						editMode={this.props.editMode}
						thumbnailsOpen={this.state.thumbnailsOpen}
						toggleDrawMode={this.toggleDrawMode}
						turnOffDrawMode={this.turnOffDrawMode}

						drawMode={this.state.drawMode}
						updateFile={this.props.updateFile}
						showAnnotations={this.state.showAnnotations}
						toggleShowAnnotations={this.toggleShowAnnotations}
            menuMouseOver={this.state.menuMouseOver}
					/>
				</div>

				<div className="MediaModalActions MediaModalActionsLower">
					<div className="MediaModalCaption">
						<Typography variant="caption">
							{this.props.editMode ?
								<TextField
									type="text"
									name="title"
									placeholder="Add a caption"
									fullWidth={true}
									style={{border: 'none'}}
									InputProps={{disableUnderline: true}}
									value={this.state.fileTitle || ((file && file.name && file.name.replace(file._id+'-', '') !== file.title ) ? file.title : '')}
									onChange={(event) => {
										if (file) {
											file.title = event.target.value
											this.setState({fileTitle: event.target.value})
											this.props.updateFile(false, file)
										}

									}}
								/>
							:
								((file.name && file.name.replace(file._id+'-', '') !== file.title ) && file.title)
							}


						</Typography>
					</div>

					<div className="MediaModalActionButtons">
						{this.props.editMode &&
							<IconButton
								onClick={this.toggleDrawMode}
								aria-label="Toggle Annotion Edit Mode"
								title="Toggle Annotion Edit Mode"
								selected={this.state.drawMode}
								color={ `${this.drawMode ? 'primary' : 'secondary'}`}
                onMouseOver={this.handleMouseOver}
                onMouseOut={this.handleMouseOut}
								className={`annotation-element ${this.state.drawMode ? 'active drawingModeButton' : ''}`}
							>
								<IconEdit />
							</IconButton>
						}

							{(file.annotations && file.annotations.length > 0  || this.props.editMode) &&
								<IconButton
									onClick={this.toggleShowAnnotations}
									aria-label="Toggle Annotations"
									title="Toggle Annotions"
									selected={this.state.showAnnotations}
									className='annotation-element'
                  onMouseOver={this.handleMouseOver}
                  onMouseOut={this.handleMouseOut}
									color={ `${this.state.showAnnotations ? 'primary' : 'secondary'}`}
								>
									<IconComment />
								</IconButton>
							}



						<IconButton
							onClick={this.handleClose}
							aria-label="Close"
							title="Exit full screen"
              onMouseOver={this.handleMouseOver}
              onMouseOut={this.handleMouseOut}
						>
							<IconFullscreenExit />
						</IconButton>
					</div>
				</div>
			</Dialog>
		);
	}
}


export default MediaModal;
