import qs from 'qs-lite';

const toggleFacet = (filterTitle, value) => {
	const query = qs.parse(window.location.search.replace('?', ''));
	let selectedFilterValues = [];
	let pathname = window.location.pathname;

	if (query[filterTitle]) {
		selectedFilterValues = query[filterTitle].split('+');
	}

	let indexOfFilter = selectedFilterValues.indexOf(value);
	if (indexOfFilter >= 0) {
		selectedFilterValues.splice(indexOfFilter, 1);
	} else {
		selectedFilterValues.push(value);
	}

	if (selectedFilterValues.length > 0) {
		query[filterTitle] = selectedFilterValues.join('+');
	} else {
		delete query[filterTitle];
	}
	query.page = 1;

	let facetedCards = document.getElementsByClassName('facetedCardsContentCards')[0];
	if (facetedCards) {
		let rect = facetedCards.getBoundingClientRect(),
    	scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		window.scrollTo(0, rect.top + scrollTop - 10)
	}

	window.location.replace(window.location.pathname + "?" + qs.stringify(query));
}

export default toggleFacet;
