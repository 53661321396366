import React from 'react';
import $ from 'jquery';
import OpenSeadragon from 'openseadragon';
import autoBind from 'react-autobind';
import Dialog from '@material-ui/core/Dialog';
import Overlay from './Overlay';
import AnnotationDetail from '../AnnotationDetail';

import './AnnotationList.css';

class AnnotationList extends React.Component {
	  constructor(props) {
	    super(props);
	    this.container = null;
	    this.state = {
	      currentShape: [],
	      shapeList: [],
				annotationList: [],
	      isDrawingMode: true,
				activeAnnotationI: 0,
				annotationMouseOver: false

	    };
			autoBind(this);
	  }


		handleMouseOver() {
			this.annotationMouseOver(true)
		}
		handleMouseOut() {
			this.annotationMouseOver(false)
		}

	  componentDidMount() {
	    this.container = document.getElementById('annotator');
	    document.addEventListener('click', this.onClick);
			if (this.props.file && this.props.file.annotations) {
				this.setState({annotationList: this.props.file.annotations})
			}
	  }


		setActiveAnnotationI(i) {
			this.props.setActiveAnnotationI(i)
			this.setState({
				activeAnnotationI: i
			});
		}


		componentDidUpdate(prevProps) {

			if (prevProps.file && this.props.file.name !== prevProps.file.name) {
				this.setState({annotationList: this.props.file.annotations})
			}
		}

	  componentWillUnmount() {
	    this.container.removeEventListener('click', this.onClick);
	    document.removeEventListener('keydown', this.onKeyDown);

	  }

		handleClick(e, i) {
			e.stopPropagation
			this.setActiveAnnotationI(i);
			this.props.setActiveAnnotationI(i);
		}

		drawAnnotations() {
			let leftOffset = 0;
			if ( $('#thumbnailContainer').width() ) {
				leftOffset =  $('#thumbnailContainer').width() + 40;
			}

			let dots = $('circle').map((i, circle) => {
				const pos = $(circle).position();
				const isActive = i === this.state.activeAnnotationI;
				return <div
					key={'annotationDisplay-'+i}
					className={`annotationDisplay annotation-element ${isActive ? ' active ' : ''}`}
					onMouseOver={this.handleMouseOver}
					onMouseOut={this.handleMouseOut}
					onClick={		(e) => {this.handleClick(e, i)} }
					style={{
						top: pos.top,
						left: pos.left - leftOffset,
					}}
					>

					</div>
			})
			return dots;
		}


		onClick = (event)  => {
			// this.props.hideAnnotationsDetails()

			if (!this.props.isDrawingMode) return;
      if (this.props.menuMouseOver) return;
			if (this.state.annotationMouseOver) return;
      if (!this.props.editMode) return;

			const viewport = this.getImageViewport();


			let thumbnailWidth = $('#thumbnailContainer').width() + 60 || 0;
			// Normalize coordinates

			let annotatorWidth = $('#annotator').width();
			const x = (((event.clientX - viewport.originX - thumbnailWidth) / viewport.width) * 100) ;
			const y = ((event.clientY - viewport.originY) / viewport.height) * 100;


			if (x > 100 || y > 100 || x < 0 || y < 0) {
				return
			}
			let list = this.state.annotationList
			//todo (daniel) fix type hack
			list.push({x: `${x}`,y: `${y}`})

			this.setState({
				annotationList: list
			});

			let file = this.props.file;

			file.annotations = list;
			this.props.updateFile(false, file);
			this.props.afterNewAnnotation();

	  }

	  getImageViewport = () => {
	    const { openSeadragon, tile } = this.props;
			if (!openSeadragon) return;
	    const imageOrigin = tile.imageToViewerElementCoordinates(
	      new OpenSeadragon.Point(0, 0)
	    );
	    const imageSize = tile.imageToViewerElementCoordinates(tile.getContentSize());

			let scale =  (imageSize.x - imageOrigin.x) / imageSize.x;
	    return {
	      originX: imageOrigin.x,
	      originY: imageOrigin.y,
	      width: imageSize.x - imageOrigin.x,
	      height: imageSize.y - imageOrigin.y,
				scale: scale
	    };
	  };


		toggleAnnotations() {
			this.setState({
				annotationsOpen: !this.state.annotationsOpen
			});
		}

		annotationMouseOver(value) {
			this.setState({annotationMouseOver: value})
		}



	  render() {
	    const { annotationList, currentShape } = this.state;
	    const { openSeadragon, isDrawingMode } = this.props;
	    return (
	      <div id="annotator" className={`annotator ${isDrawingMode ? 'drawingMode' : 'broadCastEvents '}` }>
	       {openSeadragon && <Overlay
	          shapeList={annotationList}
	          currentShape={currentShape}
	          getImageViewport={this.getImageViewport}
	          openSeadragon={openSeadragon}
						onclick={this.onClick}
						activeAnnotationI={this.state.activeAnnotationI}
						setActiveAnnotationI={this.setActiveAnnotationI}
						annotationMouseOver={this.annotationMouseOver}
						file={this.props.file}
	        />}

					{this.drawAnnotations()}

	      </div>
	    );
	  }
	}

export default AnnotationList;
