import React from 'react';
import PropTypes from 'prop-types';
import _s from 'underscore.string';
import linkifyHtml from 'linkifyjs/html';
import Typography from '@material-ui/core/Typography';

import ItemMetaFieldMap from '../ItemMetaFieldMap';
import ItemMetaFieldMedia from '../ItemMetaFieldMedia';
import ItemMetaFieldItem from '../ItemMetaFieldItem';
import ItemMetaFieldDate from '../ItemMetaFieldDate';


import './ItemMetaField.css';


const ItemMetaField = ({ type, label, value }) => {
	let elem = null;

	if (!value
		|| (
			typeof value === 'string'
		&& !value.length
	)	|| (
			typeof value === 'object'
		&& !value.length
	)) {
		return elem;
	}

	switch (type) {
	case 'text':
		elem = (
			<div className="itemMetaField">
				<div className="itemMetaFieldLabel">
					<Typography variant="caption">
						{label}
					</Typography>
				</div>
				<div className="itemMetaFieldValue">
					{(value.length === _s.stripTags(value).length) ?
						<Typography variant="body2"
							dangerouslySetInnerHTML={{ __html: linkifyHtml(value) }}
						/>
						:
						<div
							dangerouslySetInnerHTML={{ __html: value }}
						/>
					}
				</div>
			</div>
		);
		break;
	case 'number':
		elem = (
			<div className="itemMetaField">
				<div>
					<Typography variant="caption">
						{label}
					</Typography>
				</div>
				<div>
					<Typography variant="body2">
						{value}
					</Typography>
				</div>
			</div>
		);
		break;
	case 'date':
		elem = (
			<ItemMetaFieldDate
				label={label}
				value={value}
			/>
		);
		break;
	case 'place':
		elem = (
			<ItemMetaFieldMap
				label={label}
				value={value}
			/>
		);
		break;
	case 'item':
		elem = (
			<ItemMetaFieldItem
				label={label}
				value={value}
			/>
		);
		break;
	case 'media':
		elem = (
			<ItemMetaFieldMedia
				label={label}
				value={value}
			/>
		);
		break;
	default:
		elem = (
			<div className="itemMetaField">
				<div className="itemMetaFieldLabel">
					<Typography variant="caption">
						{label}
					</Typography>
				</div>
				<div className="itemMetaFieldValue">
					{(value.length === _s.stripTags(value).length) ?
						<Typography variant="body2"
							dangerouslySetInnerHTML={{ __html: linkifyHtml(value) }}
						/>
						:
						<div
							dangerouslySetInnerHTML={{ __html: value }}
						/>
					}
				</div>
			</div>
		);
		break;
	}

	return elem;
}

ItemMetaField.propTypes = {
	type: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.string,
};


export default ItemMetaField;
