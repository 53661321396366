import React from 'react';
import { compose } from 'react-apollo';
import autoBind from 'react-autobind';
import slugify from 'underscore.string/slugify';
import stripTags from 'underscore.string/stripTags';
import mime from 'mime-types';

// components
import Item from '../../../../components/common/Item';

// graphql
import postDetailQuery from '../../../issues/graphql/queries/postDetail';

// lib
import dateFormat from '../../../../lib/dateFormat';


class PostContainer extends React.Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	handleRemove(itemId) {
		const { itemRemove, history } = this.props;

		itemRemove(itemId)
			.then((response) => {
				history.replace('/items');
			})
			.catch((err) => {
				console.error(err);
			});
	}

	render() {
		let item = {};
		let issue = {};
		let userIsAdmin = false;
		let archiveTitle = '';
		let loading = this.props.loading;

		if (
			this.props.issue
			&& this.props.issue.length
		) {
			issue = this.props.issue[0];


			item = {
				_id: issue.id,
				slug: slugify(issue.title),
				title: issue.title,
				description: '',
				files: [],
				metadata: [],
			};

			issue.customFields.forEach(customField => {
				if (customField.name !== 'Subtitle' && customField.name !== 'Date' && !customField.value.endsWith('uploads')) {
					item.metadata.push({
						type: 'text',
						label: customField.name,
						value: customField.value,
					});
				} else if (customField.name === 'Date') {
					item.metadata.push({
						type: 'text',
						label: customField.name,
						value: dateFormat(customField.value),
					});
				}
			});
			let editorsColumn = item.metadata.filter((datum) => datum.label === 'Editor’s Column')[0];
			let aboutAuthors = item.metadata.filter((datum) => datum.label === 'About the Authors')[0];
			item.metadata = item.metadata.filter((datum) => datum.label !== 'Editor’s Column' && datum.label !== 'About the Authors');

			const descriptionFields = ['<strong>Table of Contents</strong>'];
			if (editorsColumn) {
				descriptionFields.push(`<a href='${editorsColumn.value}' target='_blank'>Editor's Column</a>`);
			}

			issue.articles.forEach(article => {
				const authorFields = [];
				article.authors.forEach(author => {
					authorFields.push(author.name);
				});

				let content = '';
				if (article.content) {
					content = stripTags(article.content);
				}
				descriptionFields.push(`<a href="${article.pdf.fileURI}"> ${authorFields.join(', ')}. ${article.title}</a>${content && '<br />'+content}`);
			});

			if (aboutAuthors) {
				descriptionFields.push(`<a href='${aboutAuthors.value}' target='_blank'>About the Authors</a>`);
			}

			item.description = descriptionFields.join('<br /><br />');

			issue.articles.forEach(article => {
				article.files.forEach(file => {
					let fileExtension = file.fileURI.split('.').pop();

					item.files.push({
						type: mime.lookup(fileExtension),
						name: file.fileURI,
					});
				});
			});
		}

		return (
			<Item
				{...item}
				loadingMedia={loading}
				loadingText={loading}
				archiveTitle="Oral Tradition"
				userIsAdmin={userIsAdmin}
				handleRemove={this.handleRemove}
			/>
		);
	}
}

export default compose(
	postDetailQuery
)(PostContainer);
