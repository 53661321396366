import React from 'react';

import CardLoadingComment from '../../cards/CardLoadingComment';


const ListLoadingComment = () => (
	<div className="list">
		<div className="listItem">
			<CardLoadingComment />
		</div>
		<div className="listItem">
			<CardLoadingComment />
		</div>
	</div>
);

export default ListLoadingComment;
