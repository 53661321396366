import React from 'react';

import Card from '../Card';


const CardLoadingComment = () => (
	<Card
		to=""
		textLong = " "
		footer = {{
			authorURL: ' ',
			authorImageURL: ' ',
			authorImageWidth: ' ',
			authorImageHeight: ' ',
			authorName: ' ',
			postedDate: new Date(),
		}}
		loading
	/>
);

export default CardLoadingComment;
