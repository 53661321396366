import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import debounce from 'lodash.debounce';
import shortid from 'shortid';
import qs from 'qs-lite';
import ReactPlayer from 'react-player';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import IconFullscreen from '@material-ui/icons/Fullscreen';
import IconCloudUpload from '@material-ui/icons/CloudUpload';
import IconDescription from '@material-ui/icons/Description';

import FileViewer from './FileViewer';
import FileUpload from './FileUpload';
import FileUploader from '../form/FileUploader';
import MediaModal from './MediaModal';

import './MediaViewer.css';


class MediaViewer extends React.Component {

	constructor (props) {
		super(props);

		this.state = {
			activeFileIndex: 0,
			file: false,
			showUploader: false,
		};

		autoBind(this);
	}

	componentDidMount = () => {
		document.addEventListener("keydown", this.handleKeydown, false);
	}

	componentWillUnmount = () => {
		document.removeEventListener("keydown", this.handleKeydown, false);
	}

	componentDidUpdate = () => {
		const query = qs.parse(window.location.search.replace('?', ''));
		if (query.viewMedia && !this.state.detailModalOpen) {
			this.setState({detailModalOpen: true});
		} else if (!query.viewMedia && this.state.detailModalOpen) {
			// this.setState({detailModalOpen: false});
		}
	}


	toggleUploader = () => {
		this.setState({
			showUploader: !this.state.showUploader,
		})
	}

	toggleFullscreen = () => {
		this.setState({
			detailModalOpen: !this.state.detailModalOpen,
		});
	}

	handleKeydown = (event) => {
		if (
			event.keyCode === 37
		) {
			this.handleMovePrevFile();
		} else if (event.keyCode === 39) {
			this.handleMoveNextFile();
		}
	}

	handleAddFiles = (files) => {
		this.setState({
			showUploader: false
		}, ()=> {
			this.props.handleAddFiles(files)
		})
	}

	handleMovePrevFile = () => {
		if (this.state.activeFileIndex > 0) {
			this.setState({
				activeFileIndex: this.state.activeFileIndex - 1,
			});
		}
	}

	handleMoveNextFile = () => {
		if (this.state.activeFileIndex < this.props.files.length - 1) {
			this.setState({
				activeFileIndex: this.state.activeFileIndex + 1,
			});
		}
	}

  openModal = (event) => {
    this.setState({detailModalOpen: true, file: this.props.files[0]})
  }

	closeUploadDialog = () => {
		this.setState({showUploader: false});
	}

	openFile = (file, i) => {
		if (this.props.files.length > 3 && i == 2) {
			this.setState({file: this.props.files[0], detailModalOpen: true});
		} else {
			this.setState({file: file, detailModalOpen: true});
		}

		const query = qs.parse(window.location.search.replace('?', ''));
		let pathname = window.location.pathname;
		query.viewMedia=true;

		/**
		this.props.history.push({
	 	 search: qs.stringify(query),
	 	 pathname,
	  });
		*/

    this.setState({detailModalOpen: true})
	}

	render = () => {
		const { editMode, isUploadingFiles } = this.props;
		const { activeFileIndex, detailModalOpen } = this.state;
		let files = this.props.files;
		if (typeof files === 'string') {
			try {
				files = JSON.parse(files);
			} catch (e) {}
		}

		const thumbnailSize = this.props.thumbnailSize || 900;

		const _classes = ['MediaViewer'];


		if (this.props.loadingMedia) {
			_classes.push('-loadingMedia');
		}


		if (this.props.isUploadingFiles) {
			_classes.push('-isUploadingFiles');
		}
		const numberOfMediaItems = files.length;
		if (numberOfMediaItems == 1) {
			_classes.push('-layoutWithOne');
		}
		else if (numberOfMediaItems == 2) {
			_classes.push('-layoutWithTwo');
		}
		else if (numberOfMediaItems > 2) {
			_classes.push('-layoutWithThree');
		}

		const _filesToDisplay = files.slice(0, 3);
		const imageDisplay = _filesToDisplay.map((file, i) => {
			let thumbnail = '';
			let type = 'default';
			let url = '';

			if (file && file.path && file.type) {

				if (file.type.indexOf("image") >= 0) {
					// let path = `//iiif.orphe.us/${file.name}/full/${thumbnailSize},/0/default.jpg`;
					type = 'image';
					let path = file.name;

					thumbnail = (
						thumbnail = <img src={path} alt={file.title} onError={(event)=>{
							event.target.src=file.name;
						}}/>
					)
				} else if (file.type.indexOf("video") >= 0) {
					type = 'video';
					// url = `https://s3.amazonaws.com/iiif-orpheus/${file.name}`;
					url = file.name;

					if (file.name === "9x2ZPrbPt-yolanda.mp4") {
						// issue with posters; until poster support fixed, use youtube embed
						thumbnail = (
							<ReactPlayer
								url="https://youtu.be/WDdEEwGXtgg"
								controls
								width="100%"
								height="320px"
							/>
						);

					} else {

						thumbnail = (
							<ReactPlayer
								url={url}
								controls
								width="100%"
								height="320px"
							/>
						);
					}

				} else if (file.type.indexOf("audio") >= 0) {
					type = 'audio';
					// url = `https://s3.amazonaws.com/iiif-orpheus/${file.name}`;
					url = file.name;
					thumbnail = (
						<ReactPlayer
							url={url}
							controls
							width="100%"
							height="60px"
						/>
					);
				} else if (file.type.indexOf("pdf") >= 0) {
					type = 'pdf';
					thumbnail = (
						<embed
							src={
		            file.path ?
		              `https://drive.google.com/viewerng/viewer?embedded=true&url=${file.path}` :
		              file.name
		           }
							width={window.innerWidth * 0.9}
							height={window.innerHeight * 0.6}
						/>
					);
				}

			}

			return (
				<li className={`MediaViewerContentItem ${file.path || file.name ? '' : '-loading'} -${type}`} key={`fileLi_${i}`} onClick={this.openModal} fileindex={i}>
					{thumbnail}
					{(_filesToDisplay.length - 1 == i && _filesToDisplay.length !== files.length)
						&& <div className="MediaViewerContentItemMore">+{files.length - _filesToDisplay.length}</div>}
				</li>
			)
		})

		const fileThumbnails = (files.map((file, fileIndex) => {
				return <FileUpload
					file={file}
					fileIndex={fileIndex}
					key={shortid.generate()}
					updateFileCb={this.props.updateFile}
					removeFile={this.props.removeFile}
					showError={()=>{console.log("error in FileUpload")}}
				/>
		}))

		let displayImages = (!this.props.isUploadingFiles || true);

		return (
			<div >
				{fileThumbnails}
				{displayImages  &&
					<div className={_classes.join(' ')}>
						{displayImages &&
							<ul>
								{imageDisplay}
							</ul>
						}

            <MediaModal
    					files={files}
    					file={this.state.file || files[0]}
    					detailModalOpen={detailModalOpen}
    					handleClose={this.toggleFullscreen}
    					updateFile={this.props.updateFile}
    					removeFile={this.props.removeFile}
    					editMode={this.props.editMode}
    				/>
					</div>
				}

				{this.props.editMode && this.props.files.length > 0 &&
					<div className="itemMediaEditButtons">
						<IconButton
							onClick={this.toggleUploader}
							aria-label="Upload files"
							title="Upload files"
							color={this.state.showUploader ? 'secondary' : 'default'}
						>
							<IconCloudUpload />
						</IconButton>
					</div>
				}

				{(this.props.editMode && this.state.showUploader) &&

					<Dialog className='itemFileUploader'
						open={this.state.showUploader}
						onClose={this.closeUploadDialog}
						scroll='body'
						>
						<DialogContent>
							<FileUploader
								handleAddFiles={this.handleAddFiles}
								className="dashboardFileUploader"
							/>
						</DialogContent>
					</Dialog>
				}

				{(this.props.editMode && this.props.files.length === 0) &&
					<FileUploader
						handleAddFiles={this.handleAddFiles}
						className="dashboardFileUploader"
					/>
				}

			</div>
		);
	}
}

MediaViewer.propTypes = {
	title: PropTypes.string,
	files: PropTypes.array,
	loadingMedia: PropTypes.bool,
	editMode: PropTypes.bool,
};

MediaViewer.defaultProps = {
	title: '',
	files: [],
};

export default MediaViewer;
