/**
 * @prettier
 */

const MAX_SEGMENTS = 30;
const timePeriods = {
	day: 1,
	week: 7,
	month: 30,
	year: 365,
	decade: 3650,
	century: 36500,
	millenium: 365000,
};

function getMonday(d) {
	d = new Date(d);
	let day = d.getDay(),
		diff = d.getDate() - day + (day == 0 ? -6 : 1);
	return new Date(d.setDate(diff));
}

function addDays(date, n) {
	let result = new Date(date);
	result.setDate(result.getDate() + n);
	return result;
}

function addMonths(date, n) {
	let result = new Date(date);
	return result.setMonth(result.getMonth() + n);
}

function addYears(date, n) {
	let result = new Date(date);
	return new Date(result.setFullYear(result.getFullYear() + n));
}

function getSegments(days) {
	const maxSegments = MAX_SEGMENTS;
	let timeInfo = {};
	Object.keys(timePeriods).some(interval => {
		let segments = days / timePeriods[interval];
		if (segments < maxSegments || interval == 'millenium') {
			timeInfo = {
				segments: Math.ceil(segments),
				interval: interval,
			};
			return true;
		}
	});
	return timeInfo;
}

export function dateShortMonthFormat(date) {
	const shortMonths = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];
	date = new Date(date);
	return `${date.getDate()} ${
		shortMonths[date.getMonth()]
	} ${date.getFullYear()}`;
}

function dateKey(date, interval) {
	var oneDay = 24 * 60 * 60 * 1000;
	let d = new Date(date);

	if (!date || !date.getFullYear) {
		return null;
	}

	if (interval == 'day') {
		return dateShortMonthFormat(date);
	} else if (interval == 'week') {
		date = getMonday(new Date(date));
		return dateShortMonthFormat(date);
	} else if (interval == 'month') {
		date = new Date(date.getFullYear(), date.getMonth(), 1);
		return dateShortMonthFormat(date);
	} else if (interval == 'year') {
		return new Date(date).getFullYear();
	} else if (interval == 'decade') {
		let year = new Date(date).getFullYear();
		let decade = Math.floor(year / 10) * 10;
		return decade;
	} else if (interval == 'century') {
		let year = new Date(date).getFullYear();
		let century = Math.floor(year / 100) * 100;
		return century;
	} else {
		return new Date(date).getFullYear();
	}

	return null;
}

export function addInterval(date, interval, amount) {
	let newDate = new Date(date);
	let days = amount;

	if (interval == 'day') {
		newDate = addDays(date, 1 * amount);
	} else if (interval == 'week') {
		newDate = addDays(date, 7 * amount);
	} else if (interval == 'month') {
		newDate = addMonths(date, 1 * amount);
	} else if (interval == 'year') {
		newDate = addYears(date, 1 * amount);
	} else if (interval == 'decade') {
		newDate = addYears(date, 10 * amount);
	} else if (interval == 'century') {
		newDate = addYears(date, 100 * amount);
	}

	return newDate;
}

export function timeSeries(values) {
	const dates = values.map(val => new Date(val));
	const max = new Date(Math.max.apply(null, dates));
	const min = new Date(Math.min.apply(null, dates));
	const dayDiff = parseInt((max - min) / (24 * 3600 * 1000 * 10));
	const period = getSegments(dayDiff);
	const segments = period.segments;
	const interval = period.interval;
	const startDate = new Date(Math.min.apply(null, dates));

	let dateLookup = {};

	for (var i = 0; i < segments; i++) {
		let date = addInterval(startDate, interval, i);
		dateLookup[dateKey(date, interval)] = 0;
	}

	dates.forEach(d => {
		let key = dateKey(d, interval);
		let value = dateLookup[key] || 0;
		dateLookup[key] = value + 1;
	});

	let series = [];
	Object.keys(dateLookup).forEach(d => {
		series.push({ x: d, y: dateLookup[d] });
	});
	let ts = series.sort((a, b) => {
		if (new Date(a.x) > new Date(b.x)) {
			return 1;
		}
		return -1;
	});

	return { timeline: ts, interval: interval };
}
