import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton';
import FontIcon from 'material-ui/FontIcon';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import muiTheme from '../../lib/muiTheme';
import Utils from '../../lib/utils';
import AvatarIcon from '../AvatarIcon';

/*
 * The annotations toggle to the right of a paragraph that toggles the
 * display of the annotations on the page
 * Returns state back to the main component Home.js
 */

class AnnotationIconToggle extends React.Component {

	getChildContext = () => {
		return { muiTheme: getMuiTheme(muiTheme) };
	}

	makeIconLabel = () => {
		let label = '';
		const users = this.props.usersGetPublicById.usersGetPublicById;

		if (users && users.length) {
			label = Utils.getUserName(users.find(u => (u._id === this.props.userIds[0])));

			if (users.length > 1) {
				label += ` and ${users.length - 1} other`;
			}
		}

		return (
			<span>{label}</span>
		);
	}

	render = () => {
		const { paragraph, openAnnotationDrawer, userIds } = this.props;
		const users = this.props.usersGetPublicById.usersGetPublicById;

		let user;
		if (users && users.length) {
			user = users.find(u => (u._id === userIds[0]));
		}

		return (
			<div>
				{(users && users.length > 0 && user) ?
					<div className="annotationIconWrapperInner padding">
						<div
							className="annotationIconToggle"
							onClick={openAnnotationDrawer.bind(this, paragraph, false)}
						>
							<AvatarIcon avatar={user.profile.avatarUrl} />
							<span className="annotationIconToggleName">
								{this.makeIconLabel()}
							</span>
						</div>
					</div>
				: ''}
			</div>
		);
	}
}

AnnotationIconToggle.childContextTypes = {
	muiTheme: PropTypes.object,
};

AnnotationIconToggle.propTypes = {
	users: PropTypes.array,
};

export default AnnotationIconToggle;
