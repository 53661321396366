const authors = {
  "data": {
    "authors": [
      {
        "id": 179,
        "name": "Adélékè Adéẹ̀kọ́"
      },
      {
        "id": 168,
        "name": "Moradewun Adejunmobi"
      },
      {
        "id": 247,
        "name": "Ainhoa Agirreazaldegi"
      },
      {
        "id": 362,
        "name": "K.E. Agovi"
      },
      {
        "id": 368,
        "name": "Sa’idu Babur  Ahmed"
      },
      {
        "id": 251,
        "name": "Xabier Aierdi"
      },
      {
        "id": 130,
        "name": "Ingrid Åkesson"
      },
      {
        "id": 551,
        "name": "Chiji Akoma"
      },
      {
        "id": 559,
        "name": "Elizabeth Shanks Alexander"
      },
      {
        "id": 593,
        "name": "Alla Alieva"
      },
      {
        "id": 215,
        "name": "Bruno Alonso"
      },
      {
        "id": 31,
        "name": "Mark C. Amodio "
      },
      {
        "id": 69,
        "name": "Flemming G. Andersen"
      },
      {
        "id": 511,
        "name": "Pertti Anttonen"
      },
      {
        "id": 267,
        "name": "Hiroyuki Araki"
      },
      {
        "id": 503,
        "name": "Samuel G. Armistead "
      },
      {
        "id": 304,
        "name": "Leslie K.  Arnovick"
      },
      {
        "id": 438,
        "name": "Teirab AshShareef"
      },
      {
        "id": 384,
        "name": "Alla Astakhova"
      },
      {
        "id": 85,
        "name": "David  Atkinson"
      },
      {
        "id": 204,
        "name": "Derek Attridge"
      },
      {
        "id": 363,
        "name": "Daniel K. Avorgbedor"
      },
      {
        "id": 510,
        "name": "Daniel Avorgbedor"
      },
      {
        "id": 174,
        "name": "Jaume Ayats"
      },
      {
        "id": 244,
        "name": "Laxaro Azkune"
      },
      {
        "id": 364,
        "name": "Chukwuma Azuonye"
      },
      {
        "id": 335,
        "name": "Sabir Badalkhan"
      },
      {
        "id": 379,
        "name": "Egbert J. Bakker "
      },
      {
        "id": 282,
        "name": "Gordon Ball"
      },
      {
        "id": 605,
        "name": "F. Odun Balogun"
      },
      {
        "id": 566,
        "name": "Yvonne Banning"
      },
      {
        "id": 219,
        "name": "Asier Barandiaran"
      },
      {
        "id": 298,
        "name": "Karen Barber"
      },
      {
        "id": 523,
        "name": "Michael Barnes"
      },
      {
        "id": 8,
        "name": "Paul Battles"
      },
      {
        "id": 329,
        "name": "Richard Bauman"
      },
      {
        "id": 613,
        "name": "Franz Bäuml"
      },
      {
        "id": 618,
        "name": "Roderick Beaton"
      },
      {
        "id": 171,
        "name": "Fay Beauchamp"
      },
      {
        "id": 346,
        "name": "Margaret Hiebert Beissinger"
      },
      {
        "id": 532,
        "name": "Felice Belle"
      },
      {
        "id": 165,
        "name": "Ildikó Bellér-Hann"
      },
      {
        "id": 560,
        "name": "Dan Ben-Amos"
      },
      {
        "id": 124,
        "name": "Mark Bender"
      },
      {
        "id": 214,
        "name": "Ross Bender"
      },
      {
        "id": 41,
        "name": "Barbara Berardi Tadié"
      },
      {
        "id": 92,
        "name": "Andrea L. Berez"
      },
      {
        "id": 44,
        "name": "Franck Bernède"
      },
      {
        "id": 42,
        "name": "Daniela Berti"
      },
      {
        "id": 490,
        "name": "Naran Bilik"
      },
      {
        "id": 84,
        "name": "Julia C. Bishop"
      },
      {
        "id": 410,
        "name": "Ryan Bishop"
      },
      {
        "id": 82,
        "name": "Belén Bistué"
      },
      {
        "id": 5,
        "name": "Virginia Blankenhorn"
      },
      {
        "id": 374,
        "name": "Julia Ann Blessing"
      },
      {
        "id": 545,
        "name": "Guillemette Bolens"
      },
      {
        "id": 66,
        "name": "Melissa Borgia"
      },
      {
        "id": 397,
        "name": "Maja Bošković-Stulli"
      },
      {
        "id": 274,
        "name": "Laure Bouquerel"
      },
      {
        "id": 522,
        "name": "David Bouvier"
      },
      {
        "id": 398,
        "name": "Vladimir Bovan"
      },
      {
        "id": 342,
        "name": "Betsy Bowden"
      },
      {
        "id": 147,
        "name": "Timothy W. Boyd"
      },
      {
        "id": 344,
        "name": "Nancy Mason Bradbury"
      },
      {
        "id": 578,
        "name": "Susan  Brandenstein Park"
      },
      {
        "id": 80,
        "name": "Ian Brodie"
      },
      {
        "id": 452,
        "name": "Jill Brody"
      },
      {
        "id": 292,
        "name": "Mary Ellen  Brown"
      },
      {
        "id": 513,
        "name": "Lina Bugiene"
      },
      {
        "id": 28,
        "name": "Jonathan Burgess"
      },
      {
        "id": 296,
        "name": "James Burns"
      },
      {
        "id": 399,
        "name": "Ɖenana Buturović"
      },
      {
        "id": 615,
        "name": "David Bynum"
      },
      {
        "id": 258,
        "name": "Jesse Byock"
      },
      {
        "id": 365,
        "name": "Sory Camara"
      },
      {
        "id": 129,
        "name": "Katherine Campbell"
      },
      {
        "id": 65,
        "name": "Lilah Grace Canevaro"
      },
      {
        "id": 501,
        "name": "Isabel Cardigos"
      },
      {
        "id": 192,
        "name": "David M. Carr"
      },
      {
        "id": 416,
        "name": "James Carrier"
      },
      {
        "id": 417,
        "name": "Achsah  Carrier"
      },
      {
        "id": 175,
        "name": "Albert Casals"
      },
      {
        "id": 373,
        "name": "Mishael Maswari Caspi"
      },
      {
        "id": 461,
        "name": "Diego Catalán"
      },
      {
        "id": 260,
        "name": "Teresa Catarella"
      },
      {
        "id": 433,
        "name": "Michael D. Cherniss"
      },
      {
        "id": 225,
        "name": "Lorenzo Cherubini"
      },
      {
        "id": 494,
        "name": "Michael Chesnutt"
      },
      {
        "id": 418,
        "name": "Christian Clerk"
      },
      {
        "id": 616,
        "name": "Margaret Clunies Ross"
      },
      {
        "id": 334,
        "name": "Robert Cochran"
      },
      {
        "id": 103,
        "name": "Coppélie Cocq"
      },
      {
        "id": 544,
        "name": "Derek Collins"
      },
      {
        "id": 444,
        "name": "Bridget  Connelly"
      },
      {
        "id": 496,
        "name": "Mary-Ann Constantine"
      },
      {
        "id": 385,
        "name": "Mary P. Coote"
      },
      {
        "id": 350,
        "name": "Andrew Cowell"
      },
      {
        "id": 491,
        "name": "Robert Payson Creed"
      },
      {
        "id": 14,
        "name": "Hilaria Cruz"
      },
      {
        "id": 619,
        "name": "Robert Culley"
      },
      {
        "id": 471,
        "name": "James M. Curtis"
      },
      {
        "id": 177,
        "name": "Katarzyna Mikulska Dąbrowska"
      },
      {
        "id": 462,
        "name": "Manuel da Costa Fontes"
      },
      {
        "id": 278,
        "name": "Michael Daley"
      },
      {
        "id": 95,
        "name": "Kate Darian-Smith"
      },
      {
        "id": 284,
        "name": "Ademola Omobewaji Dasylva"
      },
      {
        "id": 573,
        "name": "Nora Marks Dauenhauer"
      },
      {
        "id": 574,
        "name": "Richard L. Dauenhauer"
      },
      {
        "id": 456,
        "name": "Olga Merck Davidson"
      },
      {
        "id": 178,
        "name": "Laura Davies"
      },
      {
        "id": 386,
        "name": "Sioned Davies"
      },
      {
        "id": 149,
        "name": "Adam Brooke Davis"
      },
      {
        "id": 568,
        "name": "Craig R. Davis"
      },
      {
        "id": 463,
        "name": "Beatriz Mariscal de Rhett"
      },
      {
        "id": 46,
        "name": "Anne de Sales"
      },
      {
        "id": 233,
        "name": "Thérèse de Vet"
      },
      {
        "id": 125,
        "name": "Sabine Dedenbach-Salazar Sáenz"
      },
      {
        "id": 453,
        "name": "Luisa Del Giudice"
      },
      {
        "id": 439,
        "name": "Frederick M. Denny"
      },
      {
        "id": 387,
        "name": "Marilynn Desmond"
      },
      {
        "id": 275,
        "name": "Emmanuel Désveaux"
      },
      {
        "id": 79,
        "name": "Beverley Diamond"
      },
      {
        "id": 380,
        "name": "Keith Dickson"
      },
      {
        "id": 197,
        "name": "Juris Dilevko"
      },
      {
        "id": 353,
        "name": "A. Nicholas Doane"
      },
      {
        "id": 411,
        "name": "Carol Dougherty"
      },
      {
        "id": 2,
        "name": "Michael D. C. Drout"
      },
      {
        "id": 327,
        "name": "Lalita du Perron"
      },
      {
        "id": 158,
        "name": "Thomas A. DuBois"
      },
      {
        "id": 521,
        "name": "Casey Dué"
      },
      {
        "id": 64,
        "name": "William Duffy"
      },
      {
        "id": 206,
        "name": "Dianne Dugaw"
      },
      {
        "id": 609,
        "name": "Joseph J. Duggan"
      },
      {
        "id": 400,
        "name": "Zdeslav Dukat"
      },
      {
        "id": 256,
        "name": "Vincent A. Dunn"
      },
      {
        "id": 221,
        "name": "John Eastlake"
      },
      {
        "id": 234,
        "name": "Owain Edwards"
      },
      {
        "id": 388,
        "name": "Mark W. Edwards"
      },
      {
        "id": 246,
        "name": "Andoni Egaña"
      },
      {
        "id": 242,
        "name": "Estitxu Eizagirre"
      },
      {
        "id": 608,
        "name": "Maureen Eke"
      },
      {
        "id": 472,
        "name": "Ruth El Saffar"
      },
      {
        "id": 320,
        "name": "Yaakov Elman"
      },
      {
        "id": 78,
        "name": "David F. Elmer"
      },
      {
        "id": 514,
        "name": "Yang Enhong"
      },
      {
        "id": 391,
        "name": "Willi Erzgräber"
      },
      {
        "id": 571,
        "name": "Larry  Evers"
      },
      {
        "id": 18,
        "name": "Nigel Fabb"
      },
      {
        "id": 502,
        "name": "Marcia Farr"
      },
      {
        "id": 473,
        "name": "Thomas J. Farrell"
      },
      {
        "id": 330,
        "name": "Patrick Feaster"
      },
      {
        "id": 419,
        "name": "Steven Feld"
      },
      {
        "id": 587,
        "name": "Walter Feldman"
      },
      {
        "id": 117,
        "name": "Margaret Field"
      },
      {
        "id": 524,
        "name": "Elizabeth C. Fine"
      },
      {
        "id": 228,
        "name": "Outi Fingerroos"
      },
      {
        "id": 333,
        "name": "Margalit Finkelberg"
      },
      {
        "id": 193,
        "name": "Ruth Finnegan"
      },
      {
        "id": 420,
        "name": "Raymond Firth"
      },
      {
        "id": 191,
        "name": "Talya Fishman"
      },
      {
        "id": 226,
        "name": "Andrea Fishman"
      },
      {
        "id": 61,
        "name": "Joyce Burkhalter Flueckiger"
      },
      {
        "id": 67,
        "name": "Caitlin Flynn"
      },
      {
        "id": 111,
        "name": "John Miles Foley"
      },
      {
        "id": 332,
        "name": "Wakefield Foster"
      },
      {
        "id": 23,
        "name": "James J. Fox"
      },
      {
        "id": 558,
        "name": "Steven D. Fraade"
      },
      {
        "id": 366,
        "name": "Rachel I. Fretz"
      },
      {
        "id": 60,
        "name": "Peter Friedlander"
      },
      {
        "id": 272,
        "name": "Nicolas Froeliger"
      },
      {
        "id": 11,
        "name": " Frog"
      },
      {
        "id": 99,
        "name": "Patricia Fumerton"
      },
      {
        "id": 98,
        "name": "Dmitri A. Funk"
      },
      {
        "id": 367,
        "name": "Graham Furniss"
      },
      {
        "id": 45,
        "name": "Martin Gaenszle"
      },
      {
        "id": 547,
        "name": "John F. García"
      },
      {
        "id": 133,
        "name": "R. Scott Garner"
      },
      {
        "id": 154,
        "name": "Lori Ann Garner"
      },
      {
        "id": 245,
        "name": "Joxerra Garzia"
      },
      {
        "id": 108,
        "name": "Lara Rosenoff Gauvin"
      },
      {
        "id": 112,
        "name": "Chao Gejin"
      },
      {
        "id": 540,
        "name": "Bai Gengsheng"
      },
      {
        "id": 300,
        "name": "Andrew Gerstle"
      },
      {
        "id": 610,
        "name": "Eliza Miruna Ghil"
      },
      {
        "id": 40,
        "name": "Pustak Ghimire"
      },
      {
        "id": 243,
        "name": "Josu Goikoetxea"
      },
      {
        "id": 248,
        "name": "Arkaitz Goikoetxea"
      },
      {
        "id": 59,
        "name": "Ann Grodzins Gold"
      },
      {
        "id": 198,
        "name": "Patrick Gonder"
      },
      {
        "id": 106,
        "name": "Geoff Goodman"
      },
      {
        "id": 107,
        "name": "Valeda Dent Goodman"
      },
      {
        "id": 236,
        "name": "Susan Gorman"
      },
      {
        "id": 369,
        "name": "Veronika Görög-Karady"
      },
      {
        "id": 239,
        "name": "Florence Goyet"
      },
      {
        "id": 182,
        "name": "William Graham"
      },
      {
        "id": 202,
        "name": "Emily Greenwood"
      },
      {
        "id": 230,
        "name": "Helen Gregory"
      },
      {
        "id": 135,
        "name": "Morgan E. Grey"
      },
      {
        "id": 518,
        "name": "H.C. Groenewald"
      },
      {
        "id": 229,
        "name": "Joan Gross"
      },
      {
        "id": 341,
        "name": "Vladimir Guerrero"
      },
      {
        "id": 173,
        "name": "Wang Guoming"
      },
      {
        "id": 289,
        "name": "Frank Gurrmanamana"
      },
      {
        "id": 538,
        "name": "Zhambei Gyaltsho"
      },
      {
        "id": 563,
        "name": "Sabine Habermalz"
      },
      {
        "id": 517,
        "name": "Thomas A. Hale"
      },
      {
        "id": 594,
        "name": "Harry Halén"
      },
      {
        "id": 509,
        "name": "Joel M. Halpern"
      },
      {
        "id": 370,
        "name": "Lee Haring"
      },
      {
        "id": 340,
        "name": "Joseph Harris"
      },
      {
        "id": 277,
        "name": "Todd Harvey"
      },
      {
        "id": 96,
        "name": "Lauri Harvilahti"
      },
      {
        "id": 474,
        "name": "Eric A. Havelock"
      },
      {
        "id": 440,
        "name": "Adnan Haydar"
      },
      {
        "id": 339,
        "name": "Edward R. Haymes"
      },
      {
        "id": 190,
        "name": "Holly Hearon"
      },
      {
        "id": 595,
        "name": "Walter Heissig"
      },
      {
        "id": 151,
        "name": "Dave Henderson"
      },
      {
        "id": 265,
        "name": "David Henige"
      },
      {
        "id": 307,
        "name": "Robert  Henke"
      },
      {
        "id": 542,
        "name": "Song Heping"
      },
      {
        "id": 189,
        "name": "Catherine Hezser"
      },
      {
        "id": 153,
        "name": "Carolyn Higbie"
      },
      {
        "id": 347,
        "name": "John M. Hill"
      },
      {
        "id": 580,
        "name": "Jane Hill"
      },
      {
        "id": 527,
        "name": "Yamashita Hiroaki"
      },
      {
        "id": 231,
        "name": "Tammy Ho Lai-ming"
      },
      {
        "id": 144,
        "name": "Holly Hobbs"
      },
      {
        "id": 614,
        "name": "Elizabeth Hoffman"
      },
      {
        "id": 17,
        "name": "David Holm"
      },
      {
        "id": 567,
        "name": "Ingrid Holmberg"
      },
      {
        "id": 596,
        "name": "Lauri Honko"
      },
      {
        "id": 188,
        "name": "Richard A. Horsley"
      },
      {
        "id": 200,
        "name": "Ruth House Webber"
      },
      {
        "id": 326,
        "name": "Felicia Hughes-Freeland"
      },
      {
        "id": 21,
        "name": "Kerry Hull"
      },
      {
        "id": 421,
        "name": "Judith Huntsman"
      },
      {
        "id": 354,
        "name": "Dell Hymes"
      },
      {
        "id": 295,
        "name": "Wilt Idema"
      },
      {
        "id": 145,
        "name": "Bonnie D. Irwin"
      },
      {
        "id": 526,
        "name": "Martin S. Jaffee"
      },
      {
        "id": 43,
        "name": "Christian Jahoda"
      },
      {
        "id": 371,
        "name": "Zainab Mohamed Jama"
      },
      {
        "id": 115,
        "name": "Jan Jansen"
      },
      {
        "id": 441,
        "name": "Simon Jargy"
      },
      {
        "id": 88,
        "name": "Risto Järv"
      },
      {
        "id": 252,
        "name": "Mark Jarvis"
      },
      {
        "id": 611,
        "name": "Elizabeth and Michael Jeffreys"
      },
      {
        "id": 536,
        "name": "Xie Jisheng"
      },
      {
        "id": 205,
        "name": "Bruce Johnson"
      },
      {
        "id": 495,
        "name": "Dafydd Johnston"
      },
      {
        "id": 203,
        "name": "Chris Jones"
      },
      {
        "id": 211,
        "name": "Alice Jorgensen"
      },
      {
        "id": 348,
        "name": "Michael Joyce"
      },
      {
        "id": 20,
        "name": "Timo Kaartinen"
      },
      {
        "id": 19,
        "name": "Kati Kallio"
      },
      {
        "id": 113,
        "name": "Nesrin Kalyoncu"
      },
      {
        "id": 606,
        "name": "Russell H. Kaschula"
      },
      {
        "id": 487,
        "name": "Joshua T. Katz"
      },
      {
        "id": 401,
        "name": "Josip Kekez"
      },
      {
        "id": 187,
        "name": "Werner H. Kelber"
      },
      {
        "id": 34,
        "name": "Robert Kellogg"
      },
      {
        "id": 475,
        "name": "William J. Kennedy"
      },
      {
        "id": 273,
        "name": "Jean-Charles Khalifa"
      },
      {
        "id": 220,
        "name": "Amadu Wurie Khan"
      },
      {
        "id": 402,
        "name": "Novak Kilibarda"
      },
      {
        "id": 161,
        "name": "Margo Kitts"
      },
      {
        "id": 355,
        "name": "Anne Klein"
      },
      {
        "id": 403,
        "name": "Marija Kleut"
      },
      {
        "id": 139,
        "name": "Ruth Knezevich"
      },
      {
        "id": 4,
        "name": "Mary Knight"
      },
      {
        "id": 162,
        "name": "Paul Koerbin"
      },
      {
        "id": 389,
        "name": "Svetozar Koljević"
      },
      {
        "id": 94,
        "name": "Natalie Kononenko"
      },
      {
        "id": 597,
        "name": "Petteri Koskikallio"
      },
      {
        "id": 146,
        "name": "Wayne Kraft"
      },
      {
        "id": 404,
        "name": "Hatidža Krnjević"
      },
      {
        "id": 412,
        "name": "Koenraad Kuiper"
      },
      {
        "id": 405,
        "name": "Zmaga Kumer"
      },
      {
        "id": 72,
        "name": "Susan Smyth Kung"
      },
      {
        "id": 338,
        "name": "Kristin Kuutma"
      },
      {
        "id": 128,
        "name": "William Lamb"
      },
      {
        "id": 589,
        "name": "Sarah Lamb"
      },
      {
        "id": 576,
        "name": "Toby C. S.  Langen"
      },
      {
        "id": 97,
        "name": "Outi Lauhakangas"
      },
      {
        "id": 90,
        "name": "Marilyn Lawrence"
      },
      {
        "id": 172,
        "name": "Francesca R. Sborgi Lawson"
      },
      {
        "id": 48,
        "name": "John Leavitt"
      },
      {
        "id": 280,
        "name": "Christophe Lebold"
      },
      {
        "id": 47,
        "name": "Marie Lecomte-Tilouine"
      },
      {
        "id": 515,
        "name": "Peace B. Lee"
      },
      {
        "id": 315,
        "name": "Françoise Létoublon"
      },
      {
        "id": 537,
        "name": "Li Lianrong"
      },
      {
        "id": 35,
        "name": "Carl Lindahl"
      },
      {
        "id": 12,
        "name": "Antti Lindfors"
      },
      {
        "id": 555,
        "name": "John Lindow"
      },
      {
        "id": 164,
        "name": "Christopher Livanos"
      },
      {
        "id": 121,
        "name": "Nina E. Livesey"
      },
      {
        "id": 54,
        "name": "Cheikh Tidiane Lo"
      },
      {
        "id": 294,
        "name": "Andrew Lo"
      },
      {
        "id": 170,
        "name": "Mary Louise Lord"
      },
      {
        "id": 476,
        "name": "Albert B. Lord"
      },
      {
        "id": 352,
        "name": "LindaAnn Loschiavo"
      },
      {
        "id": 303,
        "name": "Bruce  Louden"
      },
      {
        "id": 477,
        "name": "Randolph F. Lumpp"
      },
      {
        "id": 127,
        "name": "Emily Lyle"
      },
      {
        "id": 126,
        "name": "Cathlin Macaulay"
      },
      {
        "id": 313,
        "name": "Leslie  MacCoull"
      },
      {
        "id": 600,
        "name": "E.A. Mackay"
      },
      {
        "id": 328,
        "name": "Nicolas Magriel"
      },
      {
        "id": 442,
        "name": "Muhsin Mahdi"
      },
      {
        "id": 457,
        "name": "Victor H. Mair"
      },
      {
        "id": 53,
        "name": "Robert Mann"
      },
      {
        "id": 254,
        "name": "Tullio Maranhão"
      },
      {
        "id": 590,
        "name": "Kathryn S. March"
      },
      {
        "id": 152,
        "name": "Heather Maring"
      },
      {
        "id": 70,
        "name": "Michael Marmur"
      },
      {
        "id": 500,
        "name": "J. J. Dias Marques"
      },
      {
        "id": 623,
        "name": "Nicky Marsh"
      },
      {
        "id": 520,
        "name": "Richard Martin"
      },
      {
        "id": 556,
        "name": "Ulrich Marzolph"
      },
      {
        "id": 271,
        "name": "Catharine Mason"
      },
      {
        "id": 564,
        "name": "Bruce Lionel Mason"
      },
      {
        "id": 443,
        "name": "Henry Massie"
      },
      {
        "id": 582,
        "name": "Elsie P. Mather"
      },
      {
        "id": 413,
        "name": "Jeffrey Alan Mazo"
      },
      {
        "id": 349,
        "name": "John McBratney"
      },
      {
        "id": 291,
        "name": "William Bernard McCarthy"
      },
      {
        "id": 601,
        "name": "John H. McDowell"
      },
      {
        "id": 392,
        "name": "Thomas A. McKean"
      },
      {
        "id": 351,
        "name": "Maureen N. McLane"
      },
      {
        "id": 424,
        "name": "Marivee McMath"
      },
      {
        "id": 549,
        "name": "Stephan Meyer"
      },
      {
        "id": 118,
        "name": "Jon Meza Cuero"
      },
      {
        "id": 325,
        "name": "Peter Middleton"
      },
      {
        "id": 406,
        "name": "John S. Miletich"
      },
      {
        "id": 155,
        "name": "Kayla M. Miller"
      },
      {
        "id": 180,
        "name": "Kiri Miller"
      },
      {
        "id": 305,
        "name": "J. Scott  Miller"
      },
      {
        "id": 375,
        "name": "Miriam Youngerman Miller"
      },
      {
        "id": 407,
        "name": "Nada Milošević-Ɖorđević"
      },
      {
        "id": 314,
        "name": "Elizabeth  Minchin"
      },
      {
        "id": 318,
        "name": "Deng Minwen"
      },
      {
        "id": 68,
        "name": "Christy Mitchell"
      },
      {
        "id": 493,
        "name": "Stephen Mitchell"
      },
      {
        "id": 572,
        "name": "Felipe S. Molina"
      },
      {
        "id": 445,
        "name": "James T. Monroe"
      },
      {
        "id": 381,
        "name": "Eric L. Montenyohl"
      },
      {
        "id": 216,
        "name": "Marta Morgade"
      },
      {
        "id": 546,
        "name": "Anatole Mori"
      },
      {
        "id": 583,
        "name": "Phyllis  Morrow"
      },
      {
        "id": 575,
        "name": "Marya Moses"
      },
      {
        "id": 142,
        "name": "Rebecca Richardson Mouser"
      },
      {
        "id": 207,
        "name": "James Mulholland"
      },
      {
        "id": 429,
        "name": "Matija Murko"
      },
      {
        "id": 52,
        "name": "Chris Mustazza"
      },
      {
        "id": 29,
        "name": "Gregory Nagy"
      },
      {
        "id": 159,
        "name": "Joseph Falaky Nagy"
      },
      {
        "id": 58,
        "name": "Kirin Narayan"
      },
      {
        "id": 100,
        "name": "Eric Nebeker"
      },
      {
        "id": 279,
        "name": "Keith Negus"
      },
      {
        "id": 598,
        "name": "S. Ju. Nekljudov"
      },
      {
        "id": 238,
        "name": "Marie Nelson"
      },
      {
        "id": 311,
        "name": "Christine  Neufeld"
      },
      {
        "id": 186,
        "name": "Angelika Neuwirth"
      },
      {
        "id": 525,
        "name": "Susan Niditch"
      },
      {
        "id": 51,
        "name": "Dmitry Nikolayev"
      },
      {
        "id": 356,
        "name": "John D. Niles"
      },
      {
        "id": 499,
        "name": "Carlos Nogueira"
      },
      {
        "id": 446,
        "name": "H.T. Norris"
      },
      {
        "id": 75,
        "name": "Lillis Ó Laoire"
      },
      {
        "id": 390,
        "name": "Emmanuel Obiechina"
      },
      {
        "id": 337,
        "name": "Isidore Okpewho"
      },
      {
        "id": 312,
        "name": "Lea Olsan"
      },
      {
        "id": 458,
        "name": "Alexandra Hennessey Olsen"
      },
      {
        "id": 454,
        "name": "Walter J. Ong"
      },
      {
        "id": 455,
        "name": "Jeff Opland"
      },
      {
        "id": 422,
        "name": "Margaret Orbell"
      },
      {
        "id": 212,
        "name": "Andy Orchard"
      },
      {
        "id": 264,
        "name": " ORTRAD-L"
      },
      {
        "id": 297,
        "name": "Martin Orwin"
      },
      {
        "id": 531,
        "name": "Elizabeth Oyler"
      },
      {
        "id": 114,
        "name": "Cemal Özata"
      },
      {
        "id": 222,
        "name": "Valentina Pagliai"
      },
      {
        "id": 423,
        "name": "Teaea Parima"
      },
      {
        "id": 489,
        "name": "Chan Park"
      },
      {
        "id": 209,
        "name": "Patricia Parker"
      },
      {
        "id": 134,
        "name": "Ward Parks"
      },
      {
        "id": 382,
        "name": "James B. Pearce"
      },
      {
        "id": 498,
        "name": "J. M. Pedrosa"
      },
      {
        "id": 119,
        "name": "Carole Pegg"
      },
      {
        "id": 148,
        "name": "Raymond F. Person, Jr."
      },
      {
        "id": 235,
        "name": "Lene Petersen"
      },
      {
        "id": 464,
        "name": "Suzanne H. Petersen"
      },
      {
        "id": 109,
        "name": "Tom Pettitt"
      },
      {
        "id": 105,
        "name": "Charles Maurice Pigott"
      },
      {
        "id": 306,
        "name": "Erik  Pihel"
      },
      {
        "id": 63,
        "name": "Ryan Platte"
      },
      {
        "id": 486,
        "name": "Della Pollock"
      },
      {
        "id": 425,
        "name": "Wendy Pond"
      },
      {
        "id": 150,
        "name": "Andrew E. Porter"
      },
      {
        "id": 7,
        "name": "Duncan Poupard"
      },
      {
        "id": 217,
        "name": "David Poveda"
      },
      {
        "id": 554,
        "name": "Barry B. Powell"
      },
      {
        "id": 57,
        "name": "Leela Prasad"
      },
      {
        "id": 317,
        "name": "Bamo Qubumo"
      },
      {
        "id": 143,
        "name": "Catherine Quick"
      },
      {
        "id": 530,
        "name": "Shelley Fenno Quinn"
      },
      {
        "id": 430,
        "name": "Wilhelm Radloff"
      },
      {
        "id": 393,
        "name": "Joan N. Radner"
      },
      {
        "id": 308,
        "name": "Burton Raffel"
      },
      {
        "id": 25,
        "name": "Gloria Goodwin Raheja"
      },
      {
        "id": 599,
        "name": "B.A. Viveka Rai"
      },
      {
        "id": 591,
        "name": "A. K. Ramanujan"
      },
      {
        "id": 140,
        "name": "Peter Ramey"
      },
      {
        "id": 602,
        "name": "Jarold Ramsey"
      },
      {
        "id": 131,
        "name": "Slavica Ranković"
      },
      {
        "id": 562,
        "name": "Susan J. Rasmussen"
      },
      {
        "id": 408,
        "name": "Jelka Ređep"
      },
      {
        "id": 478,
        "name": "John G. Rechtien"
      },
      {
        "id": 157,
        "name": "Steve Reece"
      },
      {
        "id": 434,
        "name": "Karl Reichl"
      },
      {
        "id": 38,
        "name": "Annalee C. Rejhon"
      },
      {
        "id": 250,
        "name": "Alfredo Retortillo"
      },
      {
        "id": 74,
        "name": "Nicole Revel"
      },
      {
        "id": 91,
        "name": "Dwight F. Reynolds"
      },
      {
        "id": 185,
        "name": "David Rhoads"
      },
      {
        "id": 208,
        "name": "Neil Rhodes"
      },
      {
        "id": 534,
        "name": " Rinchindorji"
      },
      {
        "id": 357,
        "name": "Pamela Ritch"
      },
      {
        "id": 479,
        "name": "Elias L. Rivers"
      },
      {
        "id": 459,
        "name": "Brynley F. Roberts"
      },
      {
        "id": 310,
        "name": "Lisa  Robeson"
      },
      {
        "id": 466,
        "name": "Mercedes Díaz Roig"
      },
      {
        "id": 276,
        "name": "Christopher Rollason"
      },
      {
        "id": 465,
        "name": "Antonio Sánchez Romeralo"
      },
      {
        "id": 358,
        "name": "Bruce A. Rosenberg"
      },
      {
        "id": 81,
        "name": "Bruce Rosenstock"
      },
      {
        "id": 227,
        "name": "Katrin Rupp"
      },
      {
        "id": 359,
        "name": "Joseph Russo"
      },
      {
        "id": 16,
        "name": "Jukka Saarinen"
      },
      {
        "id": 557,
        "name": "Michael Saenger"
      },
      {
        "id": 163,
        "name": "Minako Sakata"
      },
      {
        "id": 302,
        "name": "William Merrit Sale"
      },
      {
        "id": 194,
        "name": "Paula Sanders"
      },
      {
        "id": 249,
        "name": "Jon Sarasua"
      },
      {
        "id": 447,
        "name": "George D. Sawa"
      },
      {
        "id": 414,
        "name": "William Sayers"
      },
      {
        "id": 409,
        "name": "Tome Sazdov"
      },
      {
        "id": 448,
        "name": "Dirghām H. Sbait"
      },
      {
        "id": 383,
        "name": "Ursula Schaefer"
      },
      {
        "id": 324,
        "name": "Edward Schieffelin"
      },
      {
        "id": 141,
        "name": "Claire Schmidt"
      },
      {
        "id": 87,
        "name": "William Schneider"
      },
      {
        "id": 184,
        "name": "Gregor Schoeler"
      },
      {
        "id": 372,
        "name": "Rüediger Schott"
      },
      {
        "id": 435,
        "name": "William C. Scott"
      },
      {
        "id": 552,
        "name": "Antonio Scuderi"
      },
      {
        "id": 467,
        "name": "Judith Seeger"
      },
      {
        "id": 436,
        "name": "Charles Segal"
      },
      {
        "id": 480,
        "name": "Dennis P. Seniff"
      },
      {
        "id": 56,
        "name": "Mahesh Sharma"
      },
      {
        "id": 481,
        "name": "Peter Sharratt"
      },
      {
        "id": 166,
        "name": "Raushan Sharshenova"
      },
      {
        "id": 76,
        "name": "John Shaw"
      },
      {
        "id": 167,
        "name": "Eric Shepherd"
      },
      {
        "id": 624,
        "name": "Victoria Sheppard"
      },
      {
        "id": 622,
        "name": "Kenneth Sherwood"
      },
      {
        "id": 73,
        "name": "Joel Sherzer"
      },
      {
        "id": 136,
        "name": "Bruce E. Shields"
      },
      {
        "id": 299,
        "name": "Haruo Shirane"
      },
      {
        "id": 50,
        "name": "Jillian G. Shoichet"
      },
      {
        "id": 508,
        "name": "Amy Shuman"
      },
      {
        "id": 431,
        "name": "Edgard Richard Sienaert"
      },
      {
        "id": 492,
        "name": "Gísli Sigurðsson"
      },
      {
        "id": 550,
        "name": "Anna-Leena Siikala"
      },
      {
        "id": 612,
        "name": "Joseph H. Silverman"
      },
      {
        "id": 262,
        "name": "Matthew Simpson"
      },
      {
        "id": 621,
        "name": "Penelope Skarsouli"
      },
      {
        "id": 360,
        "name": "Susan Slyomovics"
      },
      {
        "id": 3,
        "name": "Leah Smith"
      },
      {
        "id": 432,
        "name": "John D. Smith"
      },
      {
        "id": 394,
        "name": "Joseph Sobol"
      },
      {
        "id": 395,
        "name": "Paul Sorrell"
      },
      {
        "id": 449,
        "name": "Saad A. Sowayan"
      },
      {
        "id": 450,
        "name": "R. Marston Speight"
      },
      {
        "id": 123,
        "name": "Editorial Staff"
      },
      {
        "id": 482,
        "name": "Harold M. Stahmer"
      },
      {
        "id": 323,
        "name": "Isolde Standish"
      },
      {
        "id": 488,
        "name": "Maria V. Stanyukovich"
      },
      {
        "id": 483,
        "name": "Thomas J. Steele"
      },
      {
        "id": 15,
        "name": "Eila Stepanova"
      },
      {
        "id": 183,
        "name": "Suzanne Pinckney Stetkevych"
      },
      {
        "id": 516,
        "name": "Beverly Stoeltje"
      },
      {
        "id": 237,
        "name": "Nancy P. Stork"
      },
      {
        "id": 586,
        "name": "Leslie Stratyner"
      },
      {
        "id": 376,
        "name": "Madeline Sutherland"
      },
      {
        "id": 460,
        "name": "Richard M. Swiderski"
      },
      {
        "id": 26,
        "name": "Venla Sykäri"
      },
      {
        "id": 507,
        "name": "Timothy R. Tangherlini"
      },
      {
        "id": 240,
        "name": "Fatos Tarifa"
      },
      {
        "id": 22,
        "name": "Lotte Tarkka"
      },
      {
        "id": 156,
        "name": "Aaron Phillip Tate"
      },
      {
        "id": 93,
        "name": "Nick Thieberger"
      },
      {
        "id": 281,
        "name": "Richard Thomas"
      },
      {
        "id": 427,
        "name": "Allen Thomas"
      },
      {
        "id": 529,
        "name": "Sybil Thornton"
      },
      {
        "id": 570,
        "name": "Barre Toelken"
      },
      {
        "id": 528,
        "name": "Alison Tokita"
      },
      {
        "id": 607,
        "name": "Keyan Tomaselli"
      },
      {
        "id": 468,
        "name": "Maximiano Trapero"
      },
      {
        "id": 218,
        "name": "Sam Tsang"
      },
      {
        "id": 426,
        "name": "Ineleo Tuia"
      },
      {
        "id": 13,
        "name": "Myfany Tuprin"
      },
      {
        "id": 101,
        "name": "Mark Turin"
      },
      {
        "id": 36,
        "name": "Frederick Turner"
      },
      {
        "id": 196,
        "name": "Lee Edgar Tyler"
      },
      {
        "id": 286,
        "name": "Françoise Ugochukwu"
      },
      {
        "id": 83,
        "name": "Ríonach uí Ógáin"
      },
      {
        "id": 137,
        "name": "Derek Updegraff"
      },
      {
        "id": 519,
        "name": "M. D. Usher"
      },
      {
        "id": 469,
        "name": "Ana Valenciano"
      },
      {
        "id": 484,
        "name": "Paolo Valesio"
      },
      {
        "id": 506,
        "name": "Ülo Valk"
      },
      {
        "id": 485,
        "name": "Frans Jozef van Beeck"
      },
      {
        "id": 565,
        "name": "Deborah VanderBilt"
      },
      {
        "id": 585,
        "name": "Vaira Vikis-Freibergs"
      },
      {
        "id": 176,
        "name": "Mercè Vilar"
      },
      {
        "id": 89,
        "name": "Evelyn Birge Vitz"
      },
      {
        "id": 6,
        "name": "Maria Vivod"
      },
      {
        "id": 428,
        "name": "John D. Waiko"
      },
      {
        "id": 269,
        "name": "Warren S. Walker"
      },
      {
        "id": 86,
        "name": "Robert Young Walser"
      },
      {
        "id": 581,
        "name": "George B.  Wasson"
      },
      {
        "id": 345,
        "name": "Johnathan Watson"
      },
      {
        "id": 603,
        "name": "Robin Waugh"
      },
      {
        "id": 285,
        "name": "Anthony K. Webster"
      },
      {
        "id": 377,
        "name": "Debra Wehmeyer-Shaw"
      },
      {
        "id": 210,
        "name": "John Wesley"
      },
      {
        "id": 27,
        "name": "Emily West"
      },
      {
        "id": 505,
        "name": "Linda White"
      },
      {
        "id": 116,
        "name": "Elizabeth Wickett"
      },
      {
        "id": 553,
        "name": "Andrew Wiget"
      },
      {
        "id": 577,
        "name": "Darryl Babe Wilson"
      },
      {
        "id": 77,
        "name": "Andrew Wiseman"
      },
      {
        "id": 9,
        "name": "Charles D. Wright"
      },
      {
        "id": 535,
        "name": "Wu Xiaodong"
      },
      {
        "id": 120,
        "name": "Elizaveta Yamaeva"
      },
      {
        "id": 322,
        "name": "Naoko Yamagata"
      },
      {
        "id": 541,
        "name": "Lang Ying"
      },
      {
        "id": 287,
        "name": "Helen Yitah"
      },
      {
        "id": 104,
        "name": "Qu Yongxian"
      },
      {
        "id": 343,
        "name": "Samantha Zacher"
      },
      {
        "id": 512,
        "name": "Jonas Zdanys"
      },
      {
        "id": 223,
        "name": "Paulu Zedda"
      },
      {
        "id": 533,
        "name": "Steve Zeitlin"
      },
      {
        "id": 497,
        "name": "John Zemke"
      },
      {
        "id": 579,
        "name": "Ofelia Zepeda"
      },
      {
        "id": 539,
        "name": " Zhalgaa"
      },
      {
        "id": 138,
        "name": "Sarah Zurhellen"
      }
    ]
  }
};
export default authors;
