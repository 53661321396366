/**
 * @prettier
 */

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconEdit from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import _s from 'underscore.string';
import autoBind from 'react-autobind';
import cn from 'classnames';
import qs from 'qs-lite';
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertFromRaw,
} from 'draft-js';
import { Link } from 'react-router-dom';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import ItemLoadingPlaceholder from '../../../modules/items/components/ItemLoadingPlaceholder';
import MediaViewer from '../MediaViewer';
// import MediaViewer from '@bit/archimedes_digital.orpheus.media-viewer';
import Tags from '../../../modules/tags/components/Tags';

import ItemMetaField from '../../../modules/items/components/ItemMetaField';
import ItemMetaFields from '../../../modules/items/components/ItemMetaFields';
import OrpheusEditor from '../../../modules/orpheus-editor/components/Editor';
import PageMeta from '../PageMeta';

import decorators from '../../../modules/orpheus-editor/components/decorators';

import './Item.css';

const VALID_BLOCK_TYPES = [
  'unstyled',
  'paragraph',
  'header-one',
  'header-two',
  'header-three',
  'header-four',
  'header-five',
  'header-six',
  'unordered-list-item',
  'ordered-list-item',
  'blockquote',
  'code-block',
  'atomic',
];

const fixBlockTypes = raw => {
  const { entityMap } = raw;
  let entityKey = Object.keys(entityMap).length;

  // this is not a generic solution,
  // but it should work for our specific case

  if (!raw || !raw.blocks) {
    // todo (daniel) fix this.  page would break without this check in development, don't understand implications
    return raw;
  }

  const blocks = raw.blocks.map(block => {
    if (VALID_BLOCK_TYPES.includes(block.type)) {
      return block;
    }

    const type = block.type.toUpperCase();
    const data = block.data;

    if (type === 'IMAGE') {
      data.src = block.data.url;
    }

    if (type === 'EMBED') {
      data.url =
        (data.embedData && data.embedData.url) ||
        (data.embed_data && data.embed_data.url);
    }

    if (type === 'ITEM') {
      data.itemId = block.data.itemId;
    }

    const entity = {
      data,
      mutability: 'IMMUTABLE',
      type,
    };

    entityMap[entityKey] = entity;

    block.text = ' ';
    block.type = 'atomic';
    block.data = {};
    block.entityRanges.push({
      key: entityKey,
      length: 1,
      offset: 0,
    });

    entityKey++;

    return block;
  });

  return { blocks, entityMap };
};

const getDescriptionEditorState = description => {
  let content;

  try {
    content = JSON.parse(description);
    content = fixBlockTypes(content);
    content = convertFromRaw(content);
  } catch (e) {
    console.error(`Failed to parse description: ${e}. Converting it to JSON.`);

    // FIXME: (charles) This is a hack to deal with pre-Draft.js descriptions
    // that are stored in plain text. If we know a bit more about their
    // structure, we might be able to convert them in a less cludgy way.
    const blocks = convertFromHTML(`<p>${description}</p>`);
    content = ContentState.createFromBlockArray(
      blocks.contentBlocks,
      blocks.entityMap
    );
  }

  return EditorState.createWithContent(content, decorators);
};

class Item extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tabValue: 0,
      metafieldsPreview: true,
      metaDialogOpen: false,
    };
    autoBind(this);
  }

  async handleChange(event, tabValue) {
    await this.props.history.push({
      pathname: window.location.pathname,
      search: `?tab=${tabValue}`,
    });
  }

  togglePreview() {
    this.setState({
      metaDialogOpen: !this.state.metaDialogOpen,
    });
  }

  closeMetaDialog() {
    this.setState({
      metaDialogOpen: false,
    });
  }

  render() {
    const {
      loadingMedia,
      loadingText,
      files,
      classes,
      title,
      archiveTitle,
      metadata,
      tags,
      description,
      annotations,
      collections,
      userIsAdmin,
    } = this.props;

    const _classes = cn('item', {
      '-loadingMedia': loadingMedia,
      '-loadingText': loadingText,
      '-media': files,
    });

    const queryParams = qs.parse(window.location.search.replace('?', ''));

    let tabValue = 0;
    if (queryParams.tab) {
      tabValue = parseInt(queryParams.tab, 10);
    }

    const descriptionEditorState =
      description && getDescriptionEditorState(description);

    // Set this to true to see the new media viewer template.
    var newMediaPrototype = true;

    let hasDescription = false;
    if (description) {
      try {
        let desc = JSON.parse(description);
        if (desc.blocks) {
          hasDescription = desc.blocks.some(d => d.text && d.text.length > 1);
        }
      } catch {
        hasDescription = description.length > 1;
      }
    }

    return (
      <div className={_classes}>
        {title ? (
          <PageMeta pageTitle={`${title} — ${archiveTitle}`} />
        ) : (
          <PageMeta pageTitle={`Item in ${archiveTitle}`} />
        )}
        {title && (
          <div className="itemHeader">
            <h1>
              {title}
            </h1>
          </div>
        )}

        {files && files.length > 0 && (
          <MediaViewer
            loadingMedia={loadingMedia}
            files={files}
            editMode={false}
            thumbnailSize={1200}
          />
        )}

        {loadingText ? (
          <ItemLoadingPlaceholder />
        ) : (
          <React.Fragment>
            {hasDescription && this.state.metafieldsPreview && (
              <div className="itemContentDescription">
                <OrpheusEditor editorState={descriptionEditorState} readOnly />
              </div>
            )}
            <div className="itemMetadata">
              {(metadata && metadata.length )? (
                <div className="itemContentMetadataGroup">
                  <div className="itemContentMetadataColumn itemContentMetadataColumnLeft">
                    <Typography variant="h3">
                      Properties
                    </Typography>
                  </div>
                  <div className="itemContentMetadataColumn itemContentMetadataColumnRight">
                    <ItemMetaFields
                      metafields={metadata}
                      isPreview={this.state.metafieldsPreview}
                      togglePreview={this.togglePreview}
                    />
                  </div>
                </div>
              ): ''}
              {(tags && tags.length) ? (
                <div className="itemContentMetadataGroup">
                  <div className="itemContentMetadataColumn itemContentMetadataColumnLeft">
                    <Typography variant="h3">
                      Tags
                    </Typography>
                  </div>
                  <div className="itemContentMetadataColumn itemContentMetadataColumnRight">
              			<div className="itemMetaField">
              				<div className="itemMetaFieldLabel">
              					<Typography variant="caption">
                          Predictive tags generated by machine learning (experimental).
              					</Typography>
              				</div>
              				<div className="itemMetaFieldValue">
                        <Tags
                          tags={tags}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ): ''}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

Item.propTypes = {
  loadingMedia: PropTypes.bool,
  loadingText: PropTypes.bool,
  heroImage: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  title: PropTypes.string,
  metadata: PropTypes.arrayOf(
    PropTypes.shape({
      metaLabel: PropTypes.string,
      metaValue: PropTypes.string,
    })
  ),
  tags: PropTypes.arrayOf(PropTypes.string),
  content: PropTypes.string,
  classes: PropTypes.object,
};

const mapStateToProps = state => ({
  userId: state.auth.userId,
});

const mapDispatchToProps = dispatch => ({});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Item);
