/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const PageMeta = props => {
  let title = `${props.pageTitle} — ${props.titleAppendix}`;

  if (props.noPageTitleAppendix) {
    title = props.pageTitle;
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={props.description} />
    </Helmet>
  );
};

PageMeta.propTypes = {
  noPageTitleAppendix: PropTypes.bool,
  pageTitle: PropTypes.string,
  titleAppendix: PropTypes.string,
};

PageMeta.defaultProps = {
  pageTitle: '',
  description: 'Archivists at over 50 instituions like the Harvard Libraries and Peabody Museum use Orpheus to create and share beautiful digital archives. In 2019, Orpheus is launching to the public.',
  titleAppendix: 'Orpheus',
};

export default PageMeta;
