import React from 'react';
import { compose } from 'react-apollo';
import { annotationQuery } from '../graphql/queries/annotations';
import { bookQuery } from '../graphql/queries/books';
import { tenantQuery } from '../graphql/queries/tenants';
import { getAuthedUserQuery } from '../graphql/queries/users';
import { userUpdatePositionMutation } from '../graphql/mutations/users';

import ReadingEnvironment from '../components/ReadingEnvironment';

const ReadingEnvironmentContainer = (props) => {
	const book = props.bookQuery.bookByChapter;

	// If book is not found in database, do not make the updated reading environment
	// with annotations for it
	if (!book) {
		// return null;
	}

	return (
		<ReadingEnvironment
			{...props}
		/>
	);
};

export default compose(
	tenantQuery,
	bookQuery,
	annotationQuery,
	getAuthedUserQuery,
	userUpdatePositionMutation,
)(ReadingEnvironmentContainer);
