import React from 'react';
import qs from 'qs-lite';
import autoBind from 'react-autobind';
import Button from '@material-ui/core/Button';
import IconHighlightOff from '@material-ui/icons/HighlightOff';
import Typography from '@material-ui/core/Typography';


import toggleFacet from '../../../lib/toggleFacet';
import clearDateFilters from '../../../lib/clearDateFilters';
import { dateShortMonthFormat } from '../../../lib/dateHelpers';


import './SelectedFilters.css';


class SelectedFilters extends React.Component {

	constructor(props) {
		super(props);
		autoBind(this);
	}

	clearFilters() {
		window.location.replace(window.location.pathname);
	}

	removeFilter(filter, value) {
		if (filter.dateFilter) {
			clearDateFilters(filter.key, value);
		} else {
			toggleFacet(filter.key, value);
		}
	}

	render() {
		let filters = [];
		let dateFields = []
  	const query = qs.parse(window.location.search.replace('?', ''));

		for (let key in query) {
			if (['search', 'page', 'dateFields'].indexOf(key) < 0) {
				filters.push({
					key,
					values: query[key].split('+'),
				});
			}

			if (key == 'dateFields') {
				dateFields = query[key].split('+')
			}
		}
		// exception for storybook
		if (window.location.host === 'localhost:9009') {
			filters = [];
		}

		let visibleFilters = filters.filter((filter) => {
			let test = filter.key.replace("_Max", '').replace("_Min", '')
			return !dateFields.includes(test)
		})

		if (dateFields) {
			dateFields.forEach((fieldTitle) => {
				let maxParam = filters.filter((f) => f.key == fieldTitle+'_Max');
				let minParam = filters.filter((f) => f.key == fieldTitle+'_Min');
				if (maxParam && minParam) {
					let dateMax = maxParam[0].values[0]
					let dateMin = minParam[0].values[0]
					visibleFilters.push({key: fieldTitle, values: [dateMin +' — '+ dateMax  ], dateFilter: true})

				}
			})
		}


		if (visibleFilters.length > 0) {
			return (
				<div className="selectedFilters">
					{visibleFilters.map(filter => (
						<div className="activeFilter" key={filter.key}>
							<Typography className="activeFilterLabel">
								{filter.key}
							</Typography>
							<div className="activeFilterValues">
								{filter.values.map(value => (
									<button
										className="activeFilterValue"
										key={value}
	            			onClick={() => {
											this.removeFilter(filter, value);
										}}
									>
										<Typography>
											{(value.match(/\d{4}-\d{2}-\d{2}/) ? dateShortMonthFormat(value) : value)}
										</Typography>
										<IconHighlightOff />
									</button>
								))}
							</div>
						</div>
					))}
					{filters.length ?
						<Button
							onClick={this.clearFilters}
							className="selectedFiltersClearAllButton"
							fullWidth
						>
	            Clear all filters
						</Button>
						: ''}
				</div>
			);
		} else {
			return null;
		}
	}
}

export default SelectedFilters;
