/**
 * @prettier
 */

import React from 'react';

import './ItemLoadingPlaceholder.css';

const ItemLoadingPlaceholder = props => (
	<div className="item">
		<div className="itemLoading">
			<div className="itemLoadingHeader" />
			<div className="itemLoadingTags" />
			<div className="itemLoadingContent" />
		</div>
	</div>
);

export default ItemLoadingPlaceholder;
