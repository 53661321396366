import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import ItemMetaField from '../ItemMetaField';

import './ItemMetaFields.css';

const ItemMetaFields = ({ metafields, isPreview, togglePreview }) => {
	const previewLength = 1000;
	if (!metafields) {
		return null;
	}

	const _metafields = [];

	// Join metadata values with the same label
	metafields.forEach(metafield => {
		if (_metafields.some(_metafield => (metafield.label === _metafield.label))) {
			_metafields.forEach(_metafield => {
				if (metafield.label === _metafield.label) {
					const values = [_metafield.value, metafield.value];
					_metafield.value = values.join(', ');
				}
			});
		} else {
			_metafields.push(metafield);
		}
	});

	metafields.forEach(metafield => {
		if (metafield.type === 'item' && typeof metafield.value === 'string') {
			try {
				metafield.value = JSON.parse(metafield.value);
			} catch (e) {
				console.warn(`Failed to parse ${metafield.value} as JSON.`);
			}
		}
	})

	return (
		<div className="itemMetaFields">

			{_metafields.map((metafield, i) => (
				<ItemMetaField
					key={`${metafield.label}-${i}`}
					{...metafield}
				/>
			))}
		</div>
	);
}

ItemMetaFields.propTypes = {
	metafields: PropTypes.array,
	isPreview: PropTypes.bool,
	togglePreview: PropTypes.func,
};

ItemMetaFields.defaultProps = {
	metafields: [],
};

export default ItemMetaFields;
