/**
 * @prettier
 */

import React from 'react';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import mapboxgl from 'mapbox-gl';
import { TextField } from 'redux-form-material-ui';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_PUBLIC_TOKEN;

const MARKER_COLOR = '#4668F2'; // the default color used by mapbox geocoder
const SAVEABLE_POINT_LAYER_IDS = ['landuse', 'park', 'settlement-label'];

class MetadataFieldMapInput extends React.Component {
	static propTypes = {
		initialValue: PropTypes.object,
		// input comes from the Redux Form <Field> component
		input: PropTypes.shape({
			onChange: PropTypes.func,
			value: PropTypes.object,
		}),
		interactive: PropTypes.bool,
	};

	static defaultProps = {
		interactive: true,
	};

	constructor(props) {
		super(props);

		this.mapRef = React.createRef();
		this.onChange = props.interactive && props.input.onChange;

		autoBind(this);
	}

	componentDidMount() {
		this._initializeMap();
	}

	componentWillUnmount() {
		this.map.remove();
	}

	render() {
		return <div className="br mh300 mw100 mapInput" ref={this.mapRef} />;
	}

	/*
	TODO: use a less intrusive way of getting user location (guesstimate based on IP address)
	_handleBrowserGeolocation(pos) {
		if (pos && pos.coords && this.map) {
			this.map.setCenter([pos.coords.longitude, pos.coords.latitude]);
		}
	}
	*/

	_handleGeocoderResult({ result }) {
		this._removeMarker();
		this.onChange(result);
	}

	_handleMapDblClick(e) {
		const { point, lngLat } = e;

		this.geocoder.clear();
		this._removeMarker();
		this._placeMarker(lngLat);

		const features = this.map.queryRenderedFeatures(point);

		if (features && features.length) {
			const feature = features.find(
				f =>
					(f.layer && SAVEABLE_POINT_LAYER_IDS.includes(f.layer.id)) ||
					(f._geometry && f._geometry.type === 'Point')
			);

			if (feature) {
				feature.center = feature.center || [lngLat.lng, lngLat.lat];

				return this.onChange(feature);
			}
		}

		this.onChange(lngLat);
	}

	_initializeMap() {
		const { initialValue, interactive } = this.props;
		let center = [-74, 40.7]; // New York, NY

		if (initialValue && initialValue.hasOwnProperty('center')) {
			center = initialValue.center;
		} else if (initialValue && initialValue.hasOwnProperty('position')){
			center = initialValue.position;
		} else if (initialValue && initialValue.hasOwnProperty('lng')){
			center = initialValue;
		}

		this.map = new mapboxgl.Map({
			center,
			container: this.mapRef.current,
			interactive,
			style: 'mapbox://styles/mapbox/streets-v11',
			zoom: 16,
		});

		if (initialValue) {
			this._placeMarker(center);
		}

		if (interactive) {
			this.geocoder = new MapboxGeocoder({
				accessToken: mapboxgl.accessToken,
				flyTo: true,
				mapboxgl,
				promixity: center,
			});

			this.map.on('dblclick', this._handleMapDblClick);
			this.geocoder.on('result', this._handleGeocoderResult);
			this.map.addControl(this.geocoder);
		}
	}

	_placeMarker(lngLat) {
		this.marker = new mapboxgl.Marker({ color: MARKER_COLOR, draggable: true })
			.setLngLat(lngLat)
			.addTo(this.map);
	}

	_removeMarker() {
		this.marker && this.marker.remove();
		this.marker = null;
	}
}

export default MetadataFieldMapInput;
