import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

import AnnotationCreateContainer from '../../containers/AnnotationCreateContainer';
import AnnotationContainer from '../../containers/AnnotationContainer';

class AnnotationDrawer extends React.Component {

	state = {
		edit: false,
		editAnnotation: null,
	}

	renderAnnotations = () => {
		const {
			saveAnnotation, removeAnnotation, toggleUserModal, loggedInUser, book,
			annotations, paragraph
		} = this.props;

		return annotations.map((annotation, i) => {
			if (annotation.paragraphN !== paragraph.i) {
				return null;
			}

			return (
				<AnnotationContainer
					key={i}
					book={book}
					{...annotation}
					userIds={annotation.users}
					saveAnnotation={saveAnnotation}
					removeAnnotation={removeAnnotation}
					loggedInUser={loggedInUser}
					toggleUserModal={toggleUserModal}
				/>
			);
		});
	}

	render = () => {
		const {
			open, createMode, paragraph, saveAnnotation, closeAnnotationDrawer,
			tenantId,
		 } = this.props;

		const styles = {};

		if (open && 'xOffset' in paragraph) {
			styles.annotationDrawer = {
				top: (paragraph.yOffset + 14),
			};
		}

		let legacyMode = Cookies.get('legacyMode');

		if (legacyMode === 'true') {
			legacyMode = true;
		} else {
			legacyMode = false;
		}

		if (legacyMode) {
			return null;
		}

		if (open) {
			return (
				<div
					className="annotationDrawer"
					style={styles.annotationDrawer}
				>
					<div className="drawerHeader">
						<span className="drawerLabel">Annotations</span>
						<span
							className="drawerLabel drawerClose"
							onClick={closeAnnotationDrawer.bind(this)}
						>
							Close
						</span>
					</div>
					{createMode ?
						<AnnotationCreateContainer
							tenantId={tenantId}
							paragraphN={paragraph.i}
							closeEditMode={closeAnnotationDrawer}
						/>
					:
						<div className="annotationsList">
							{this.renderAnnotations()}
						</div>
					}
				</div>
			);
		}

		return null;
	}

}

AnnotationDrawer.propTypes = {
	open: PropTypes.bool,
	createMode: PropTypes.bool,
	paragraph: PropTypes.object,
	book: PropTypes.object,
	annotations: PropTypes.array,
	saveAnnotation: PropTypes.func,
	removeAnnotation: PropTypes.func,
	closeAnnotationDrawer: PropTypes.func,
	loggedInUser: PropTypes.object,
	toggleUserModal: PropTypes.func,
};

export default AnnotationDrawer;
