import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { Provider as ReduxProvider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core/styles';

import CustomTheme from '../../lib/muiTheme.js';
import client from '../../middleware/apolloClient';
import store from '../../store';

const Root = (props) => (
	<ApolloProvider client={client}>
		<ReduxProvider store={store}>
			<CookiesProvider>
				<ThemeProvider theme={CustomTheme}>
					<SnackbarProvider anchorOrigin={{
		        vertical: 'bottom',
		        horizontal: 'right',
	    		}}>
						{props.children}
					</SnackbarProvider>
				</ThemeProvider>
			</CookiesProvider>
		</ReduxProvider>
	</ApolloProvider>
);

export default Root;
