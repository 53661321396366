import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import S3Upload from 'react-s3-uploader/s3upload';
import autoBind from 'react-autobind';
import shortid from 'shortid';
import IconClose from '@material-ui/icons/Close';
import CircularProgress from "@material-ui/core/CircularProgress";

import './FileUpload.css';


const DragHandle = SortableHandle(() => (
	<div className="FileUploadMoveButton">
		<i className="mdi mdi-bars" />
	</div>
));


export default class FileUpload extends React.Component {
	constructor(props) {
		super(props);

		autoBind(this);
	}

	componentWillMount() {
		if (
			process.env.REACT_APP_BUCKET_URL
			&& this.props.file
			&& !this.props.file.path
		) {
			this._id = shortid.generate();
			this.uploadFile();
		}
	}

	uploadFile() {
		new S3Upload({
			onProgress: this.handleProgress,
			onFinishS3Put: this.handleFinish,
			fileElement: { files: [this.props.file] },
			signingUrl: '/s3/sign',
			server: process.env.REACT_APP_SERVER,
			onError: this.handleError,
			uploadRequestHeaders: {'x-amz-acl': 'public-read'},
			contentDisposition: 'auto',
			s3path: '',
			scrubFilename: (filename) => {
        const secureFilename = filename.replace(/[^\w\d_\-\.]+/ig, ''); // eslint-disable-line
				return `${this._id}-${secureFilename}`;
			},
			signingUrlMethod: 'GET',
			signingUrlWithCredentials: true
		});
	}

	handleError(err) {
		console.error(err);
	}

	handleProgress(percentage) {
		this.setState({
			progress: percentage,
		});
	}

	handleFinish(event) {
		const file = {
			name: event.filename,
			title: this.props.file.name,
			type: this.props.file.type,
			path: `${process.env.REACT_APP_BUCKET_URL}/${event.filename}`,
			thumbPath: `https://iiif.orphe.us/${event.filename}/full/90,/0/default.jpg`,
			_id: this._id
		};
		if (this.props.updateFileCb && typeof(this.props.updateFileCb === 'function')) {
			this.props.updateFileCb(this.props.fileIndex, file);
		}
	}

	render() {
		return '';
	}
}
