import React from 'react';
import OpenSeadragon from 'openseadragon';
import autoBind from 'react-autobind';
import Dialog from '@material-ui/core/Dialog';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

import './AnnotationDetail.css';

class AnnotationDetail extends React.Component {
	  constructor(props) {
	    super(props);
	    this.state = {

	    };
			autoBind(this);
	  }

	  componentDidMount() {

	  }


		handleDelete() {
			let file = this.props.file;
			 file.annotations.splice(this.props.activeAnnotationI, 1 )

			this.props.updateFile(false, file)
			this.props.hideAnnotationsDetails()
		}

		componentDidUpdate(prevProps) {

			if (prevProps.file && this.props.file.name !== prevProps.file.name) {
				// this.setState({annotationList: this.props.file.annotations})
			}
		}

	  componentWillUnmount() {
	    // this.container.removeEventListener('click', this.onClick);
	    // document.removeEventListener('keydown', this.onKeyDown);
	  }

	  render() {
			const file = this.props.file;
			const annotation = this.props.annotations[this.props.activeAnnotationI];
	    return (
				<div className={`AnnotationsDetailContainer ${(this.props.annotationDetailOpen  && !this.props.drawMode) && ' annotationDetailOpen'}`}>
					<div className="AnnotationsToggle" onClick={this.props.toggleAnnotations}>
						<div className="AnnotationsToggleThumb"></div>
					</div>
					{annotation && this.props.annotationDetailOpen && !this.props.drawMode  &&
						<Card className="annotationDetail">
							<CardContent>
								{this.props.updateFile ?
									<TextField
										type="text"
										name="title"
										placeholder="Add an annotation"
										fullWidth={true}
										style={{border: 'none'}}
										InputProps={{disableUnderline: true}}
										multiline
										rowsMax="4"
										rows="4"
										defaultValue={annotation.content}
										onChange={(event) => {
											if (file) {
												file.annotations[this.props.activeAnnotationI].content = event.target.value
												this.props.updateFile(false, file)
											}

										}}
									/>
								:
									annotation.content
								}
							</CardContent>
							{this.props.editMode && <CardActions>
				        <Button onClick={this.handleDelete} size="small">Delete</Button>
				      </CardActions> }
			      </Card>
					}

				</div>
	    );
	  }
	}

export default AnnotationDetail;
