/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import autoBind from 'react-autobind';
import qs from 'qs-lite';
import { AreaChart, Area, ResponsiveContainer } from 'recharts';
import InputRange from 'react-input-range';

import './react-input-range.css';
import './TimelineFilter.css';

import { timeSeries, addInterval } from './timeSeries';


class TimelineFilter extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			value: { min: 0, max: 1 },
			timeline: [],
			width: 200,
			timeline: false,
		};

		autoBind(this);
	}

	toggleDates = (filterTitle, values) => {
		const query = qs.parse(window.location.search.replace('?', ''));
		let pathname = window.location.pathname;
		let selectedFilterValues;

		if (query[filterTitle]) {
			selectedFilterValues = query[filterTitle].split('+');
		}

		let dateFields = [];
		if (query.dateFields) {
			dateFields = query.dateFields.split('+');
		}

		let indexOfDateField = dateFields.indexOf(filterTitle);
		if (indexOfDateField < 0) {
			dateFields.push(filterTitle);
		}

		query['dateFields'] = dateFields.join('+');
		query[filterTitle] = values.join('+');
		query[filterTitle + '_Max'] = this.state.timeline[this.state.value.max].x;
		query[filterTitle + '_Min'] = this.state.timeline[this.state.value.min].x;
		query.page = 1;

		let facetedCards = document.getElementsByClassName(
			'facetedCardsContentCards'
		)[0];
		if (facetedCards) {
			let rect = facetedCards.getBoundingClientRect(),
				scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			window.scrollTo(0, rect.top + scrollTop - 10);
		}

		window.location.replace(window.location.pathname + "?" + qs.stringify(query));
	}

	handleChangeComplete = (value) => {
		let activeDates = [];
		this.props.filter.values.forEach(val => {
			let date = new Date(val);
			let minDate = new Date(this.state.timeline[value.min].x);
			let maxDate = addInterval(
				new Date(this.state.timeline[value.max].x),
				this.state.interval,
				1
			);

			if (minDate <= date && date <= maxDate) {
				activeDates.push(val);
			}
		});

		this.toggleDates(this.props.filter.name, activeDates);
	}

	handleChange = (value) => {
		this.setState({ value });
	}

	componentDidMount = () => {
		const ts = timeSeries(this.props.filter.values);

		let activeDates = [];
		const query = qs.parse(window.location.search.replace('?', ''));
		let pathname = window.location.pathname;
		let selectedFilterValues;
		const filterTitle = "Date";
		let minVal = 0;
		let maxVal = ts.timeline.length - 1;

		if (query[filterTitle]) {
			selectedFilterValues = query[filterTitle].split('+');
			const maxDate = new Date(selectedFilterValues[0]);
			const minDate = new Date(selectedFilterValues[selectedFilterValues.length - 1]);
			ts.timeline.forEach((year, i) => {
				if (minDate.getFullYear().toString() === year.x) {
					minVal = i;
				}
				if (maxDate.getFullYear().toString() === year.x) {
					maxVal = i;
				}
			});
		}


		this.setState({
			timeline: ts.timeline,
			interval: ts.interval,
			value: {
				min: minVal,
				max: maxVal,
			},
			activeTimes: {},
		});
	}

	componentDidUpdate = (prevProps) => {
		/**
		const query = qs.parse(window.location.search.replace('?', ''));
		if (
			!query['dateFields'] ||
			!query['dateFields'].includes(this.props.filter.name)
		) {
			this.setState({
				value: { min: 0, max: this.state.timeline.length - 1 },
			});
		}
		*/
	}

	render() {
		const timeline = this.state.timeline;
		if (!timeline || timeline.length < 1) return 'Loading';

		const value = this.state.value;
		const activeSeries = timeline.map((date, i) => {
			let active = false;
			if (value.min <= i && i <= value.max) {
				active = date.y;
			}
			return Object.assign({ active, }, date);
		});

		// activeSeries.unshift({ active: 0, x: 0, y: 0 });
		// activeSeries.push({ active: 0, x: 0, y: 0 });

		return (
			<div className="timelineFilter">
				<ResponsiveContainer width="100%" height={75}>
					<AreaChart
						data={activeSeries}
						margin={{
							top: 5,
							right: 0,
							left: 0,
							bottom: 5,
						}}
					>
						<defs>
							<linearGradient
								id={'splitColor_' + this.props.filter.name}
								x1="0"
								y1="0"
								x2="1"
								y2="0"
							>
								<stop
									offset={
										this.state.value.min / (this.state.timeline.length - 1)
									}
									stopColor="#eeeeee"
									stopOpacity={1}
								/>
								<stop
									offset={
										this.state.value.min / (this.state.timeline.length - 1)
									}
									stopColor="#66023c"
									stopOpacity={1}
								/>
								<stop
									offset={
										this.state.value.max / (this.state.timeline.length - 1)
									}
									stopColor="#66023c"
									stopOpacity={1}
								/>
								<stop
									offset={
										this.state.value.max / (this.state.timeline.length - 1)
									}
									stopColor="#eeeeee"
									stopOpacity={1}
								/>
							</linearGradient>
						</defs>
						<Area
							type="monotone"
							dataKey="y"
							stroke="#e0e0e0"
							fill={`url(#splitColor_${this.props.filter.name})`}
						/>
					</AreaChart>
				</ResponsiveContainer>

				<InputRange
					maxValue={timeline.length - 1}
					minValue={0}
					formatLabel={value => {
						let date = timeline[parseInt(value)];
						if (date && date.x) {
							return date.x;
						}
						return value;
					}}
					value={this.state.value}
					onChange={this.handleChange}
					onChangeComplete={this.handleChangeComplete}
				/>

				<Typography>
					{timeline[this.state.value.min].x} &mdash;{' '}
					{timeline[this.state.value.max].x}
				</Typography>
			</div>
		);
	}
}

TimelineFilter.propTypes = {
	filter: PropTypes.object.isRequired,
};

export default TimelineFilter;
