import React from 'react';

import CardLoadingItem from '../../cards/CardLoadingItem';


const ListLoadingItem = () => (
	<div className="list">
		<div className="listItem">
			<CardLoadingItem />
		</div>
	</div>
);

export default ListLoadingItem;
