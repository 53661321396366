import axios from 'axios';

export const UPLOAD_FILE_S3 = 'UPLOAD_FILE_S3';
export const UPLOAD_FILE_S3_FAILURE = 'UPLOAD_FILE_S3_FAILURE';
export const UPLOAD_FILE_S3_SUCCESS = 'UPLOAD_FILE_S3_SUCCESS';
export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';


// TODO: (charles) Wire these actions up to reducers
export const uploadFileToS3 = file => dispatch => {
	dispatch({
		type: UPLOAD_FILE_S3
	});

	const params = {
		contentType: file.type,
		objectName: file.name,
	}

	return axios.get(
		`${process.env.REACT_APP_SERVER || 'https://api.orphe.us'}/s3/sign`,
		{ params }
	).then(apiResponse => {
		const { signedUrl } = apiResponse.data;
		const { body } = file;

		return axios.put(signedUrl, { data: { body } }).then(s3Response => {
			dispatch({
				type: UPLOAD_FILE_S3_SUCCESS
			});

			return apiResponse.data;
		});
	}).catch(error => {
		return dispatch({
			type: UPLOAD_FILE_S3_FAILURE,
			error,
		});
	});
};
