import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const postDetailQuery = graphql(gql`
  query postDetailQuery($name: String){
    issue(name: $name){
			id
			name
			title
      articles {
        id
        title
        content
        pdf {
          id
          fileURI
        }
				authors {
					name
				}
        files {
          id
          fileURI
        }
      }
      customFields {
        id
        issue_id
        name
        value
      }
    }
  }
`, {
	options: (props) => {
		let name = "32i"; // window.location.pathname.replace('/', '');

		return {
			variables: {
				name,
			}
		};
	},
	name: 'postDetailQuery',
	props: props => ({
		issue: props.postDetailQuery.issue,
		loading: props.postDetailQuery.loading,
	}),
});


export default postDetailQuery;
