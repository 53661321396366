import React from 'react';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import autoBind from 'react-autobind';
import _s from 'underscore.string';
import qs from 'qs-lite';

import Facet from '../Facet';
import TimelineFilter from '../TimelineFilter';

import './Filter.css';

class Filter extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			shownFacetLimit: 10,
			filterQuery: ''
		};

		autoBind(this);
	}

	triggerResize() {
		// Trigger resize event to force sticky-reactnode FacetedCards
		// to push footer down when expanidng filters beyond card column
		// Needs to wait until card is fully expanded
		setTimeout(()=>{
			window.dispatchEvent(new Event('resize'));
		},250);
	}

	showMoreFacets() {
		this.triggerResize();
		this.setState({
			shownFacetLimit: this.state.shownFacetLimit + 20,
		});
	}

	handleQueryChange(event) {
		this.setState({filterQuery: event.target.value});
	}

	render () {

		const { filter, relevantFilters } = this.props;
		const { shownFacetLimit, filterQuery } = this.state;
		let displayed_values = [];

		if (!filter.values || !filter.values.length) {
			return null;
		}

		const query = qs.parse(window.location.search.replace('?', ''));
		let selectedFilterValues = [];
		if (query[filter.name]) {
			selectedFilterValues = query[filter.name].split('+');
		}

		let activeFilter;
		let active_values = [];
		if (relevantFilters.length) {
			activeFilter = relevantFilters.find(_filter => (filter.name === _filter.name));
		}

		const queries_values = filter.values.filter((value) => {
			if (filterQuery == '') return true;
			return _s.cleanDiacritics(value).toLowerCase().includes(filterQuery.toLowerCase())
				||  value.toLowerCase().includes(filterQuery.toLowerCase());
		})
		if (activeFilter) {
			active_values = activeFilter.values.filter((value) => {
				if (filterQuery == '') return true;
				return _s.cleanDiacritics(value).toLowerCase().includes(filterQuery.toLowerCase())
					||  value.toLowerCase().includes(filterQuery.toLowerCase());
			})
		}

		displayed_values = selectedFilterValues;
		active_values.forEach(active_value => {
			if (displayed_values.indexOf(active_value) < 0) {
				displayed_values.push(active_value);
			}
		});
		queries_values.forEach(query_value => {
			if (displayed_values.indexOf(query_value) < 0) {
				displayed_values.push(query_value);
			}
		});

		const _values = displayed_values.slice(0, shownFacetLimit);

		return (
			<ExpansionPanel className="filter" onChange={this.triggerResize}>
				<ExpansionPanelSummary expandIcon={<IconExpandMore />} className="filterPanelSummary">
					<Typography className="filterPanelSummaryTitle" component="div">{filter.name}</Typography>
				</ExpansionPanelSummary>

				{((filterQuery.length > 0 || queries_values.length >= 10)) && filter.type !== 'date' ?
					<TextField
						className="filterQueryInput"
						label={'Find ' + filter.name.toLowerCase()}
						value={this.state.filterQuery}
						onChange={this.handleQueryChange}
						margin="normal"
						padding="dense"
						variant="outlined"
						fullWidth
					/>
					:  '' }


				<ExpansionPanelDetails className="filterPanelDetails">
					{filter.type && filter.type === 'date' && _values.length > 3 ?
						<TimelineFilter filter={filter} />
					:
						_values.map(facet => (
							<Facet
								key={facet}
								filterTitle={filter.name}
								value={facet}
								filterType={filter.type}
								relevantFilters={relevantFilters}
							/>))
					}

				</ExpansionPanelDetails>
				{(
					shownFacetLimit < queries_values.length
					&& filter.type !== 'date'
				)?
					<div className="filterPanelMore">
						<Button
							onClick={this.showMoreFacets}
						>
							Show more
						</Button>
					</div>
					: ''}
			</ExpansionPanel>
		);
	}
}

Filter.propTypes = {
	filter: PropTypes.object,
};

Filter.defaultProps = {
	filter: {},
};

export default Filter;
